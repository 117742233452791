import React from 'react';
import { Spinner } from 'react-bootstrap';

const SpinnerLoader = ({ noSpinnerContainer = false }) => {
  return (
    <div className={`d-flex justify-content-center ${noSpinnerContainer ? '' : 'spinner-container'}`}>
      <Spinner animation="border" variant="primary" size="md" />
    </div>
  );
};

export default SpinnerLoader;

import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import TaskLocationMarker from './TaskLocationMarker';
import TaskInfoBox from './TaskInfoBox';
import TasksList from './TasksList';
import MapAlert from './MapAlert';

const TaskMap = ({ center, zoom, tasks, setQueries, queries, isFetching }) => {
  const [showZoomAlert, setShowZoomAlert] = useState(false);
  const zoomNoResultsLimit = 10;

  const handleShowResultsDependingOnZoom = (inputZoom, coordinates) => {
    if (inputZoom < zoomNoResultsLimit) {
      setShowZoomAlert(true);
      setTimeout(() => {
        setShowZoomAlert(false);
      }, 1500);
      setQueries({ ...queries, noResults: true });
    } else {
      setShowZoomAlert(false);
      setQueries({
        ...queries,
        noResults: false,
        coordinates: JSON.stringify(coordinates)
      });
    }
  };

  const handleMapOnChange = mapInfo => {
    const {
      marginBounds: { ne: topRight, sw: bottomLeft },
      zoom: currentZoom,
      center: currentCenter
    } = mapInfo;
    const currentSessionLocation = {
      ...currentCenter,
      currentZoom
    };
    sessionStorage.setItem(
      'currentSessionLocation',
      JSON.stringify(currentSessionLocation)
    );
    const coordinates = {
      top_right: topRight,
      bottom_left: bottomLeft
    };
    handleShowResultsDependingOnZoom(currentZoom, coordinates);
  };

  const mapOptions = {
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    zoomControl: true
  };

  return (
    <>
      <div className="map-container">
        <TaskInfoBox tasks={tasks} isFetching={isFetching} />
        <MapAlert showZoomAlert={showZoomAlert} />
        <div className="map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }}
            defaultCenter={center}
            defaultZoom={zoom}
            options={mapOptions}
            onChange={handleMapOnChange}
          >
            {tasks.map(task => (
              <TaskLocationMarker
                key={task.id}
                lat={task.latitude}
                lng={task.longitude}
                task={task}
              />
            ))}
          </GoogleMapReact>
        </div>
        <div id="desktop-map-list" className="map-list desktop">
          <TasksList tasks={tasks} desktopMapShowView isFetching={isFetching} />
        </div>
      </div>
    </>
  );
};

export default TaskMap;

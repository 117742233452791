import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import {
  PaymentAmounts,
  PaymentBankInfo,
  PaymentList,
  TaskUserPaymentList
} from '../../components/Payment';
import { LinkBtn } from '../../components';
import { useFetchShiftUsers, usePaymentRequests } from '../../hooks';

const PaymentIndex = () => {
  const {
    shiftUsers,
    handleIndexRequest,
    hasMore,
    isFetching
  } = useFetchShiftUsers();
  const { user } = usePaymentRequests();
  const {
    canGetPaid,
    parsedValidatedAmount,
    parsedDoneAmount,
    taskUsers = []
  } = user;

  return (
    <>
      <h3 className="module-title">Pagos</h3>
      <section className="index-action-buttons">
        <LinkBtn
          to="/payments/history"
          className="primary-btn bg-dark-blue big"
        >
          Ver Historial
        </LinkBtn>
      </section>
      <div className="payment-layout">
        <div className="payments-data">
          <PaymentAmounts
            parsedDoneAmount={parsedDoneAmount}
            parsedValidatedAmount={parsedValidatedAmount}
          />
          <PaymentBankInfo areCompleted={canGetPaid} />
        </div>
        <div className="payments-list">
          <Tabs
            defaultActiveKey="validated_shifts"
            id="uncontrolled-tab-example"
            className="mb-5"
          >
            <Tab eventKey="validated_shifts" title="Turnos validados">
              <PaymentList
                userShifts={shiftUsers}
                handleIndexRequest={handleIndexRequest}
                hasMore={hasMore}
                isFetching={isFetching}
                noTitle
              />
            </Tab>
            {taskUsers.length > 0 && (
              <Tab eventKey="extra_payments" title="Pagos extras">
                <TaskUserPaymentList taskUsers={taskUsers} noTitle />
              </Tab>
            )}
          </Tabs>
          <div className="task-buttons--primary mt-4">
            <LinkBtn
              to="/payments/request"
              className={`primary-btn bg-dark-blue big ${
                canGetPaid && shiftUsers.length ? '' : 'disabled'
              }`}
            >
              Solicitar Pago
            </LinkBtn>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentIndex;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconGG from '../Icons/IconGG';

const TaskIndexHeader = ({ type = '', text, icon, setShowMap }) => {
  const handleOnClick = () => {
    setShowMap(m => !m);
  };

  return (
    <Row>
      <Col className="d-flex justify-content-between my-3">
        <h3>Tareas</h3>
        <div className="d-flex justify-content-between align-items-center">
          <IconGG className="green mr-3" icon={icon} size="1" />
          <Link
            className="link"
            to={`/tasks?type=${type}`}
            onClick={handleOnClick}
          >
            {text}
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default TaskIndexHeader;

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexSettingsRequest } from '../requests/settings';

import { sendAlert } from '../actions/utils';

const useFetchSettings = () => {
  const [settings, setSettings] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    setSettings(camelCaseRecursive(response.data));
    setIsFetching(false);
  };

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Oops, hubo un problema. Asegurate de tener conexión a internet e inténtalo nuevamente.';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setIsFetching(false);
  };

  const handleIndexRequest = () => {
    debounceIndexSettingsRequest({
      dispatch,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
    setIsFetching(true);
  };

  useEffect(handleIndexRequest, []);

  return {
    settings,
    isFetching,
    handleIndexRequest
  };
};

export default useFetchSettings;

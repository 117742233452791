import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import './styles.scss';

const RadioButton = ({
  abbr,
  label,
  onChange,
  className,
  options,
  defaultValue,
  setFieldTouched,
  error,
  touched,
  ...props
}) => {
  return (
    <Form.Group>
      <Form.Label>
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
      <InputGroup>
      <div className="radio-buttons">
        {
          options.map((item) => (
            <label htmlFor={item.value} key={item.value}>
              <input
                name={item.label}
                id={item.value}
                key={item.value}
                type="radio"
                value={item.value}
                checked={defaultValue === item.value}
                onChange={onChange}
                className={className}
                {...props}
              />
          {item.label}
          </label>
          ))
        }
      </div>
      </InputGroup>
      {error && touched && (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
    </Form.Group>
  );
};

RadioButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};


export default RadioButton;

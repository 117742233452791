import React from 'react';

const responseToBlob = response => {
  const format = response.headers['content-type'].split('/')[1];
  let blob = '';
  const { data } = response;
  if (
    format === 'xlsx' ||
    format === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

const parseFilename = headers => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (
    disposition &&
    (disposition.indexOf('attachment') !== -1 ||
      disposition.indexOf('inline') !== -1)
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const parseUrlParams = params => {
  const options = {};
  params
    .replace(/\?/g, '')
    .split('&')
    .forEach(item => {
      const [key, value] = item.split('=');
      options[key] = value;
    });
  return options;
};

const downloadFile = response => {
  const blob = responseToBlob(response);
  const filename = parseFilename(response.headers);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const insertString = (value, position, str) => {
  return value.slice(0, position) + str + value.slice(position);
};

const hideString = (string, num) => {
  const regex = new RegExp(`^.{${num}}`, 'g');
  return string.replace(regex, match => '*'.repeat(match.length));
};

const rutFormat = value => {
  let rut = value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  if (rut.length > 1) {
    rut = insertString(rut, -1, '-');
  }
  if (rut.length > 5) {
    rut = insertString(rut, -5, '.');
  }
  if (rut.length > 9) {
    rut = insertString(rut, -9, '.');
  }
  return rut;
};

const validRutInput = e => {
  const re = /^[0-9kK\b]+$/;
  const rawRut = e.target.value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  return e.target.value === '' || re.test(rawRut);
};

const isAlphanumeric = (value, field, setFieldValue) => {
  const alphanumeric = /^[a-zA-Z0-9!?@#$&()<>'"´_áéíóúüÁÉÍÓÚÜñÑ\-`.+,\s]*$/;
  if (alphanumeric.test(value)) {
    setFieldValue(field.name, value);
  }
};

const isNumeric = (value, field, setFieldValue) => {
  const numeric = /^[0-9]*$/;
  if (numeric.test(value)) {
    setFieldValue(field.name, value);
  }
};

const validPhoneInput = e => {
  const re = /^[0-9\b]+$/;
  return e.target.value === '' || re.test(e.target.value);
};

// eslint-disable-next-line no-useless-escape
const R_EMAIL = /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const rutValidation = rutInput => {
  // FORMAT
  const rut = rutInput
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  const body = rut.slice(0, -1);
  let dv = rut.slice(-1).toUpperCase();
  // Compute
  let sum = 0;
  let multiple = 2;
  // For every digit
  for (let i = 1; i <= body.length; i += 1) {
    // Get product
    const index = multiple * rut.charAt(body.length - i);
    // add to count
    sum += index;
    // In range [2,7]
    if (multiple < 7) {
      multiple += 1;
    } else {
      multiple = 2;
    }
  }
  // Division Remainder
  const dvComputed = 11 - (sum % 11);
  // (0 & K)
  dv = dv === 'K' ? 10 : dv;
  dv = dv === '0' ? 11 : dv;
  // Is valid?
  if (`${dvComputed}` !== `${dv}`) {
    return false;
  }
  return true;
};

const isValidRut = rut => {
  const result =
    (/^\d{1,2}\.?\d{3}\.?\d{3}[-]?[0-9kK]{1} *$/i.test(rut) ||
      /^\d{1,2}\.\d{3}\.\d{3}[-]?[0-9kK]{1} *$/i.test(rut)) &&
    rutValidation(rut);
  return result;
};

const isMobile = () => {
  let check = false;
  (a => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const formatToPrice = (amount, currency = '$') => {
  if (!amount) return '';
  return `${currency}${amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
};

const handleWorkflowStatus = status => {
  if (!status) {
    return '';
  }
  switch (status) {
    case 'approved':
      return <span className="status status-primary">APROBADO</span>;
    case 'pending':
      return <span className="status status-info">PENDIENTE</span>;
    default:
      return <span className="status status-danger">RECHAZADO</span>;
  }
};

const handleDaysShow = (item, attribute) => {
  return `${item?.[attribute]} ${item?.[attribute] === 1 ? 'día' : 'días'}`;
};

const compare = (row, dir, array) => {
  const arrayCompare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const colA = a[row.selector]?.toUpperCase() || '';
    const colB = b[row.selector]?.toUpperCase() || '';

    let reverse = 1;
    if (dir === 'asc') reverse = -1;
    if (colA > colB) return 1 * reverse;
    if (colA < colB) return -1 * reverse;
    return 0;
  };
  array.sort(arrayCompare);
};

const sortFunction = (rows, field, direction) => {
  const arrayCompare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const colA = a[field.selector]?.toUpperCase() || '';
    const colB = b[field.selector]?.toUpperCase() || '';

    let reverse = 1;
    if (direction === 'asc') reverse = -1;
    if (colA > colB) return 1 * reverse;
    if (colA < colB) return -1 * reverse;
    return 0;
  };
  if (!rows || rows.length === 0) return [];
  if (!field) return rows;
  const newArray = rows.slice(0);
  newArray.sort(arrayCompare);
  return newArray;
};

const filterArray = (array, query, keys) => {
  if (query === '') {
    return array;
  }
  if (array.length === 0) {
    return array;
  }
  return array.filter(item => {
    let inQuery = false;
    keys.forEach(key => {
      if (item[[key]]?.toLowerCase().includes(query.toLowerCase())) {
        inQuery = true;
      }
    });
    return inQuery;
  });
};

const isObject = v => typeof v === 'object';

const emptyStringRecursive = obj => {
  if (!isObject(obj)) return obj;

  const newObj = obj;
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(subObj => emptyStringRecursive(subObj));
    } else if (value === null) {
      newObj[key] = '';
    }
  });
  return newObj;
};

const camelCase = str => str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());

const camelCaseEmptyStringRecursive = obj => {
  if (obj === null) return '';

  if (Array.isArray(obj)) {
    return obj.map(value => camelCaseEmptyStringRecursive(value));
  }

  if (isObject(obj)) {
    const newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
      newObject[camelCase(key)] = camelCaseEmptyStringRecursive(value);
    });
    return newObject;
  }
  return obj;
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const delayMethod = (method, time = 150) => {
  setTimeout(method, time);
};

const sideScroll = (
  htmlElement,
  direction,
  speed = 20,
  distance = 400,
  step = 15
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    /* eslint-disable no-param-reassign */
    if (direction === 'left') {
      htmlElement.scrollLeft -= step;
    } else {
      htmlElement.scrollLeft += step;
    }
    scrollAmount += step;

    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
};

const shortText = (string, maxLenght) => {
  return string.length > maxLenght
    ? `${string.substring(0, maxLenght)}...`
    : string;
};

const groupBy = (array, attribute, optionalAttribute = null) => {
  return array.reduce((acc, current) => {
    const {
      [`${attribute}`]: key,
      [`${optionalAttribute}`]: optionalKey
    } = current;
    const vKey = key || optionalKey;
    if (acc.includes(acc.find(obj => Object.keys(obj).includes(vKey)))) {
      acc.find(
        obj => Object.keys(obj).includes(vKey) && obj[`${vKey}`].push(current)
      );
    } else {
      acc.push({ [vKey]: [current] });
    }
    return acc;
  }, []);
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export {
  capitalizeFirstLetter,
  camelCaseEmptyStringRecursive,
  compare,
  delayMethod,
  downloadFile,
  emptyStringRecursive,
  filterArray,
  formatBytes,
  formatToPrice,
  groupBy,
  handleDaysShow,
  handleWorkflowStatus,
  hideString,
  isAlphanumeric,
  isNumeric,
  isValidRut,
  isMobile,
  parseUrlParams,
  R_EMAIL,
  rutFormat,
  rutValidation,
  sideScroll,
  shortText,
  sortFunction,
  validPhoneInput,
  validRutInput
};

const currentCountry = process.env.REACT_APP_CURRENT_COUNTRY;

const chileanBanks = [
  { label: 'Banco del Estado', value: 'banco_estado' },
  { label: 'Banco de Chile', value: 'banco_de_chile' },
  { label: 'Banco Santander', value: 'banco_santander' },
  { label: 'Banco Scotiabank Chile', value: 'banco_scotiabank' },
  { label: 'Banco Crédito e Inversiones - BCI', value: 'banco_bci' },
  { label: 'Banco Itaú Chile', value: 'banco_itau' },
  { label: 'Banco Falabella', value: 'banco_falabella' },
  { label: 'Banco Internacional', value: 'banco_internacional' },
  { label: 'Banco Corpbanca', value: 'banco_corpbanca' },
  { label: 'Banco BICE', value: 'banco_bice' },
  { label: 'HSBC Bank', value: 'hsbc_bank' },
  { label: 'Banco Security', value: 'banco_security' },
  { label: 'Deutsche Bank', value: 'deutsche_bank' },
  { label: 'Banco Ripley', value: 'banco_ripley' },
  { label: 'Rabobank Chile', value: 'banco_rabobank' },
  { label: 'Banco Consorcio', value: 'banco_consorcio' },
  { label: 'Banco Penta', value: 'banco_penta' },
  { label: 'Banco París', value: 'banco_paris' },
  { label: 'Banco BBVA', value: 'banco_bbva' },
  { label: 'Banco BTG Pactual Chile', value: 'banco_btg_pactual_chile' }
];

const chileanDocumentTypes = [{ label: 'RUT', value: 'rut' }];

const chileanAccountTypes = [
  { label: 'Cuenta Corriente', value: 'checking' },
  { label: 'Cuenta Ahorro', value: 'savings' },
  { label: 'Cuenta Vista', value: 'vista' }
];

const chileanCurrencies = [{ label: 'CLP', value: 'clp' }];

const peruvianBanks = [
  { label: 'Banco de Peru 1', value: 'banco_de_peru_1' },
  { label: 'Banco de Peru 2', value: 'banco_de_peru_2' },
  { label: 'Banco de Peru 3', value: 'banco_de_peru_3' }
];

const peruvianDocumentTypes = [
  { label: 'DNI', value: 'dni' },
  { label: 'Cédula Identidad', value: 'ce' },
  { label: 'Pasaporte', value: 'passport' }
];

const peruvianAccountTypes = [
  { label: 'Cuenta Corriente', value: 'checking' },
  { label: 'Cuenta Ahorro', value: 'savings' }
];

const peruvianCurrencies = [
  { label: 'Soles', value: 'pen' },
  { label: 'Dólares', value: 'usd' }
];

// eslint-disable-next-line import/no-mutable-exports
let formOptions;

switch (currentCountry) {
  case 'CL':
    formOptions = {
      bankOptions: chileanBanks,
      accountTypeOptions: chileanAccountTypes,
      currencyOptions: chileanCurrencies,
      documentTypesOptions: chileanDocumentTypes
    };
    break;
  case 'PE':
    formOptions = {
      bankOptions: peruvianBanks,
      accountTypeOptions: peruvianAccountTypes,
      currencyOptions: peruvianCurrencies,
      documentTypesOptions: peruvianDocumentTypes
    };
    break;
  default:
    break;
}

export default formOptions;

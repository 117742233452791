import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { talentLmsUserStatusRequest } from '../../requests/user';
import TaskButtons from './TaskButtons';
import ApplyButton from './ApplyButton';
import TrainingButton from './TrainingButton';
import ShiftsList from './ShiftsList';
import CanApplyMessage from './CanApplyMessage';
import { SpinnerLoader } from '..';
import IconGG from '../Icons/IconGG';

import { useGetUrlParams } from '../../hooks';

import { shortText, isMobile } from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const TaskComponent = ({
  task,
  showView,
  forMapList,
  desktopMapShowView,
  innerRef
}) => {
  const {
    id,
    companyLogo,
    name,
    parsedTaskPayment,
    withVariablePayment,
    parsedTaskAddionalPayment,
    additionalPaymentDescription,
    campaignDescription = '',
    campaignName,
    campaignStartDate,
    campaignEndDate,
    description,
    taskAddress,
    locationName,
    shifts = [],
    taskNature,
    campaignId,
    campaignTypeIcon,
    withTraining
  } = task || {};

  const {
    navigationSettings: { currentTaskId, useSavedNavigationSettings }
  } = useSelector(state => state.utils);
  const {
    user: { can_apply: canApply }
  } = useSelector(state => state.auth);

  const [isOpen, setIsOpen] = useState(false);
  const taskImage = companyLogo?.fileUrl || '/images/default.png';
  const [trainingStatus, setTrainingStatus] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [onRequest, setOnRequest] = useState('');
  const [showApplyButton, setShowApplyButton] = useState(false);
  const { type } = useGetUrlParams();
  const fromMap = type === 'map';
  const noShiftsMessageTitle = 'Atención';
  const noShiftsMessageBody =
    'No hay turnos asociados a esta tarea. No es posible postular por el momento.';

  const handleOpenDetails = () => {
    // eslint-disable-next-line no-unused-expressions
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const campaignDates =
    !campaignStartDate && !campaignEndDate
      ? 'Sin Información'
      : `${campaignStartDate} - ${campaignEndDate}`;

  const taskRef = useRef(null);

  const handleScrollToTask = () => {
    const {
      location: { pathname }
    } = history;
    if (!['/tasks'].includes(pathname)) return;
    if (id === parseInt(currentTaskId, 10) && useSavedNavigationSettings) {
      taskRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  const handleFailureRequest = response => {
    dispatch(sendAlert({ kind: 'error', response }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    setTrainingStatus(response.data.response);
    setOnRequest(false);
  };

  const checkTrainingStatus = () => {
    if (!withTraining) {
      setOnRequest(false);
      return;
    }
    if (withTraining === undefined || campaignId === undefined || !showView)
      return;
    setOnRequest(true);

    talentLmsUserStatusRequest({
      dispatch,
      params: { campaign_id: campaignId },
      successCallback: response => handleSuccessRequest(response),
      failureCallback: handleFailureRequest
    });
  };

  const applicationButton = () => {
    if (showApplyButton && onRequest) {
      return <SpinnerLoader noSpinnerContainer />;
    }
    if (!showApplyButton && onRequest) {
      return <SpinnerLoader noSpinnerContainer />;
    }
    if (showApplyButton && !onRequest) {
      return <ApplyButton task={task} />;
    }
    if (!showApplyButton && !onRequest) {
      return (
        <TrainingButton
          task={task}
          trainingStatus={trainingStatus}
          checkTrainingStatus={checkTrainingStatus}
        />
      );
    }
    return <SpinnerLoader noSpinnerContainer />;
  };

  const handleShowApplicationButton = () => {
    if (withTraining) {
      if (canApply) {
        if (trainingStatus === 'Completed') {
          return setShowApplyButton(true);
        }
        return setShowApplyButton(false);
      }
      return setShowApplyButton(true);
    }
    return setShowApplyButton(true);
  };

  const handleShowTask = () => {
    history.push(`/tasks/${id}?fromMap=${fromMap ? 'true' : 'false'}`);
  };

  useEffect(checkTrainingStatus, [campaignId]);
  useEffect(handleScrollToTask, []);
  useEffect(handleShowApplicationButton, [trainingStatus, withTraining]);

  return (
    <>
      <div
        id={`task-${id}`}
        ref={taskRef}
        className={`task-container ${showView ? 'show-view' : ''} ${
          desktopMapShowView ? 'keep-mobile' : ''
        } ${forMapList ? 'map-list' : ''}`}
      >
        <div
          aria-hidden
          role="button"
          onClick={handleShowTask}
          className={`task ${forMapList ? 'map-list' : ''}`}
        >
          <div className="task--thumbnail">
            <img
              className="task--thumbnail__image"
              src={taskImage}
              alt="Task"
            />
          </div>
          <div ref={innerRef} className="task--info">
            <div className="info--header">
              <div className="info--header__title-container">
                {campaignTypeIcon && (
                  <IconGG className="blue" icon={campaignTypeIcon} />
                )}
                <h1 className="info--header__title">{name}</h1>
              </div>
              <div className="info--header__prices">
                <p className="info--header__price">{parsedTaskPayment}</p>
                {withVariablePayment && (
                  <p className="info--header__variable">
                    + {parsedTaskAddionalPayment}
                  </p>
                )}
              </div>
            </div>
            <div className="info--body">
              <p className="bold"> Campaña: </p>
              <p>
                {' '}
                <strong> {campaignName} </strong>{' '}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`task--description ${isOpen || showView ? 'show' : ''}`}
        >
          {showView && (
            <div className="section">
              <p className="section--title">Campaña: {campaignName}</p>
              <p className="section--body">
                {// eslint-disable-next-line no-nested-ternary
                showView
                  ? campaignDescription
                  : shortText(campaignDescription, 100)}
              </p>
            </div>
          )}
          <div className="section">
            <p className="section--title">Tus tareas como {name}</p>
            <p className="section--body">
              {// eslint-disable-next-line no-nested-ternary
              showView
                ? description
                : isMobile()
                ? shortText(description, 90)
                : shortText(description, 350)}
            </p>
          </div>
          <div className="section">
            <p className="section--title">Ubicación</p>
            <p className="section--body">
              {locationName} - {taskAddress}
            </p>
          </div>
          {showView && (
            <div className="section">
              <p className="section--title">Pago</p>
              <p className="section--body">
                {`Se pagará ${parsedTaskPayment} por ${
                  taskNature === 'daily' ? 'día trabajado' : 'turno trabajado'
                }${
                  withVariablePayment
                    ? ` + variable de ${parsedTaskAddionalPayment}.`
                    : '.'
                }`}
                {withVariablePayment && (
                  <p className="m-0">Detalle: {additionalPaymentDescription}</p>
                )}
              </p>
            </div>
          )}
          {showView && !desktopMapShowView && (
            <div className="section">
              <p className="section--title">Duración y Horarios</p>
              <div className="task--description--shifts">
                {taskNature !== 'daily' && <ShiftsList shifts={shifts} />}
                {taskNature === 'daily' && (
                  <div className="shift-list mt-3">
                    <div className="shift-item">
                      <p className="m-0">
                        <b>{name}</b>
                      </p>
                      <span>{campaignDates}</span>
                      <p className="m-0">
                        Un turno por cada día, con horario libre.
                      </p>
                    </div>
                  </div>
                )}
                <div className="highlighted-payment">
                  <p>Pago Total</p>
                  <p>
                    {`${parsedTaskPayment} por ${
                      taskNature === 'daily'
                        ? 'día trabajado'
                        : 'turno trabajado'
                    }${withVariablePayment ? ` + variable.` : '.'}`}
                  </p>
                </div>
              </div>
              {withTraining === undefined && trainingStatus === '' && (
                <SpinnerLoader noSpinnerContainer />
              )}
              {shifts.length === 0 && (
                <CanApplyMessage
                  title={noShiftsMessageTitle}
                  body={noShiftsMessageBody}
                  icon="danger"
                />
              )}
            </div>
          )}
        </div>
        {!showView && (
          <TaskButtons
            isOpen={isOpen}
            handleOpenDetails={handleOpenDetails}
            task={task}
            fromMap={fromMap}
            forMapList={forMapList}
          />
        )}
      </div>
    </>
  );
};

export default TaskComponent;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import SimpleCenteredModal from './SimpleCenteredModal';
import IconedButton from '../Sidebar/IconedButton';
import WarningModalBody from './WarningModalBody';

const DefaultModal = ({
  title,
  body,
  variantBtnClose,
  titleBtnClose,
  variantBtnSave,
  titleBtnSave,
  handleClose,
  handleConfirm,
  centered,
  size,
  show,
  ...props
}) => (
  <Modal
    {...props}
    show={show}
    onHide={handleClose}
    size={size}
    centered={centered}
  >
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
      <div className="modal-close-icon">
        <IconedButton
          icon="close-o"
          iconClass="blue"
          buttonHandler={handleClose}
        />
      </div>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
      <Button className="btn" variant={variantBtnClose} onClick={handleClose}>
        {titleBtnClose}
      </Button>
      <Button className="btn" variant={variantBtnSave} onClick={handleConfirm}>
        {titleBtnSave}
      </Button>
    </Modal.Footer>
  </Modal>
);

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  variantBtnClose: PropTypes.string,
  titleBtnClose: PropTypes.string.isRequired,
  variantBtnSave: PropTypes.string,
  titleBtnSave: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  centered: PropTypes.bool
};

DefaultModal.defaultProps = {
  variantBtnClose: 'primary',
  variantBtnSave: 'success',
  size: 'md',
  centered: true
};

const CenteredModal = ({
  header,
  title,
  body,
  titlebtn,
  size,
  onClickHide,
  ...props
}) => (
  <Modal
    {...props}
    size={size}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>{title}</h4>
      <p>{body}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClickHide}>{titlebtn}</Button>
    </Modal.Footer>
  </Modal>
);

CenteredModal.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  titlebtn: PropTypes.string.isRequired,
  size: PropTypes.string
};

CenteredModal.defaultProps = {
  header: null,
  size: 'lg'
};

const ModalCenter = props => {
  const { header, title, body, titlebtn, size, titlebtnmodal } = props;
  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => setModalShow(false);

  return (
    <ButtonToolbar>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        {titlebtnmodal}
      </Button>

      <CenteredModal
        show={modalShow}
        onHide={modalClose}
        header={header}
        title={title}
        body={body}
        titlebtn={titlebtn}
        size={size}
        titlebtnmodal={titlebtnmodal}
      />
    </ButtonToolbar>
  );
};

export { DefaultModal, ModalCenter, SimpleCenteredModal, WarningModalBody };

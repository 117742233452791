import React from 'react';
import EventItem from './EventItem';

import './style.scss';

const ListDayEvents = props => {
  const { dayEvents, selectedDate, setMoreData } = props;

  return (
    <>
      <div>
        <p className="selected-date">{selectedDate}</p>
        <div className="event-list">
          {dayEvents.length === 0 ? (
            <p className="no-events">Día sin eventos.</p>
          ) : (
            dayEvents.map(item => (
              <EventItem
                eventData={item}
                key={item.id}
                setMoreData={setMoreData}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
export default ListDayEvents;

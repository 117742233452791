import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import EventCalendar from './EventCalendar';
import { sendAlert } from '../../../actions/utils';
import { dayNames, monthNames } from './datesOptions';
import {
  debounceIndexShiftUsersRequest,
  dayEventsShiftUsersRequest
} from '../../../requests/shift_users';

const Calendar = () => {
  const shouldListDayEvents = true;
  const [clickedDay, setClickedDay] = useState('');
  const [events, setEvents] = useState({});
  const [dayEvents, setDayEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const previousClickedDay = localStorage.getItem('clickedDay');
  const alertType = 'hasBeenAlertedAboutFinishingTask';
  const [onRequest, setOnRequest] = useState(false);
  const message =
    '¡RECUERDA! Si ya terminaste tu tarea, haz click en la misma para finalizarla.';

  const formatDate = date => {
    const dayName = dayNames[date.getDay()];
    const dayNumber = date.getDate();
    const month = monthNames[date.getMonth()];
    return `${dayName} ${dayNumber} de ${month}`;
  };

  const handleEventContent = () => {
    const eventCount = '';
    const countElement = document.createElement('span');
    countElement.innerHTML = `${eventCount}`;
    return {
      domNodes: [countElement]
    };
  };

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al buscar tu información de perfil. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const fetchEvents = firstDayOfMonth => {
    if (firstDayOfMonth === undefined) return;
    debounceIndexShiftUsersRequest({
      dispatch,
      params: {
        month_params: firstDayOfMonth,
        for_calendar: true
      },
      successCallback: response =>
        setEvents(camelCaseRecursive(response.data.data)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchDayEvents = () => {
    if (!clickedDay) return;
    if (onRequest) return;
    if (selectedDate === '') setSelectedDate(formatDate(new Date()));
    setOnRequest(true);
    dayEventsShiftUsersRequest({
      dispatch,
      params: { day_params: clickedDay },
      successCallback: response => {
        setDayEvents(camelCaseRecursive(response.data.data));
        setOnRequest(false);
      },
      failureCallback: () => {
        handleFailureRequest();
        setOnRequest(false);
      }
    });
  };

  const handleDateClick = element => {
    const oldElement = document.getElementsByClassName('selected-day-bg')[0];
    if (oldElement !== undefined) {
      oldElement.classList.remove('selected-day-bg');
    }
    element.dayEl.attributes
      .getNamedItem('data-date')
      .ownerElement.classList.add('selected-day-bg');
    const currentDate = formatDate(element.date);
    setSelectedDate(currentDate);
    setClickedDay(element.dateStr);
    localStorage.setItem('clickedDay', element.dateStr);
    localStorage.setItem('currentDay', currentDate);
    setMoreData(!moreData);
  };

  const handleInit = () => {
    setClickedDay(previousClickedDay || new Date());
    if (previousClickedDay !== null) {
      const day = document.querySelector(`[data-date="${previousClickedDay}"]`);
      day.classList.add('selected-day-bg');
      const currentDate = localStorage.getItem('currentDay');
      setSelectedDate(currentDate);
    }
    localStorage.removeItem('clickedDay');
    localStorage.removeItem('currentDay');
  };

  useEffect(handleInit, []);
  useEffect(fetchEvents, []);
  useEffect(fetchDayEvents, [moreData, clickedDay]);

  return (
    <EventCalendar
      shouldListDayEvents={shouldListDayEvents}
      events={events}
      handleEventContent={handleEventContent}
      handleDateClick={handleDateClick}
      previousClickedDay={previousClickedDay}
      alertType={alertType}
      message={message}
      dayEvents={dayEvents}
      selectedDate={selectedDate}
      setMoreData={setMoreData}
      fetchEvents={fetchEvents}
    />
  );
};

export default Calendar;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';
import './styles.scss';

const FormikInput = ({
  addon,
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  formInline,
  helpText,
  inputType,
  label,
  leftAddon,
  margin,
  placeholder,
  tooltipIcon,
  tooltipRight,
  tooltipSize,
  tooltipText,
  toolbarVariant,
  touched,
  size,
  minInputHeight,
  widthInput,
  ...props
}) => (
  <Form.Group
    className={`${formInline && 'form-inline'} ${disabled ? 'disabled' : ''} ${margin} ${
      error && touched ? 'is-invalid' : ''
    }`}
  >
    {label && (
      <>
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1 mr-1">*</abbr>}
          {tooltipText && !tooltipRight && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon={tooltipIcon} width={tooltipSize} />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      </>
    )}
    <InputGroup>
      {leftAddon && (
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
        </InputGroup.Prepend>
      )}
      <Form.Control
        style={{ width: `${widthInput}px`, minHeight: `${minInputHeight}px` }}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        className={disabled && 'input-disabled'}
        type={inputType}
        {...props}
      />
      {addon && (
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">{addon}</InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
    {tooltipText && tooltipRight && (
      <ButtonToolbar className="ml-2 mt-1">
        <OverlayTrigger
          key={direction}
          placement={direction}
          delay={{ show: delayShow, hide: delayHide }}
          overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
          <Icon icon={tooltipIcon} width={tooltipSize} />
        </OverlayTrigger>
      </ButtonToolbar>
    )}
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);
FormikInput.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipSize: PropTypes.string,
  formInline: PropTypes.bool,
  tooltipRight: PropTypes.bool
};

FormikInput.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: '',
  tooltipIcon: 'help-circle',
  tooltipSize: '15',
  formInline: false,
  tooltipRight: false
};

export default FormikInput;

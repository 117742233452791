import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexUserPaymentRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/user_payments', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createUserPaymentRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', '/user_payments', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexUserPaymentsRequest = AwesomeDebouncePromise(
  indexUserPaymentRequest,
  300
);

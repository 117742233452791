import React from 'react';

const TaskUserPayment = ({ taskUser }) => {
  const { task, parsedEffectiveVariablePayment } = taskUser;
  const { name: taskName, taskAddress } = task;

  return (
    <div className="usershift-container">
      <div className="usershift one-column">
        <div className="usershift--info">
          <p className="usershift--info__taskname">{taskName}</p>
          <p className="usershift--info__location">{taskAddress}</p>
          <p className="usershift--info__payment">
            Pago:
            <span className="payment ml-3">
              {parsedEffectiveVariablePayment}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TaskUserPayment;

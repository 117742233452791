import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

const handleDefaultMultiValue = (value, defaultValue, defaultMultiValues) => {
  if (defaultValue && value && value === defaultValue)
    return defaultMultiValues;
  return undefined;
};

const findDefaultValue = (value, defaultValue, options, props) => {
  const { isMulti, defaultMultiValues } = props;
  if (isMulti) {
    return handleDefaultMultiValue(value, defaultValue, defaultMultiValues);
  }
  if (
    defaultValue &&
    value &&
    defaultValue !== '' &&
    value !== '' &&
    value === defaultValue
  ) {
    const vAttribute = options.filter(e => defaultValue === e.value);
    return vAttribute[0];
  }
  if (value === '') return null;
  return undefined;
};

const colourStyles = {
  control: base => ({
    ...base,
    fontSize: '1.4rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    minHeight: '32px',
    height: '33px'
  }),
  menu: base => ({
    ...base,
    fontSize: '1.4rem',
    backgroundColor: 'white'
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#ACE1DB' : null,
      color: '#333333'
    };
  }
};

const FormikSelect = ({
  options,
  label,
  abbr,
  customOption,
  error,
  touched,
  helpText,
  name,
  defaultValue,
  value,
  isDisabled,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group
      controlId={label}
      className={`${isDisabled ? 'disabled' : ''} ${
        error && touched ? 'is-invalid' : ''
      }`}
    >
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                ?
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <Select
        className={`${isOpen ? 'border-on' : ''}`}
        placeholder="Buscar..."
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay opciones'}
        options={options}
        isSearchable
        name={name}
        value={findDefaultValue(value, defaultValue, options, props)}
        isDisabled={isDisabled}
        {...props}
        styles={colourStyles}
      />
      {error && touched && (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default FormikSelect;

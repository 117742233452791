  const countryCodes = [
    {
      countryName: 'Chile',
      value: '+56',
      label: '+56'
    },
    {
      countryName: 'Perú',
      value: '+51',
      label: '+51'
    },
    {
      countryName: 'Argentina',
      value: '+54',
      label: '+54'
    },
    {
      countryName: 'Bolivia',
      value: '+591',
      label: '+591'
    },
    {
      countryName: 'Brasil',
      value: '+55',
      label: '+55'
    },
    {
      countryName: 'Colombia',
      value: '+57',
      label: '+57'
    },
    {
      countryName: 'Ecuador',
      value: '+593',
      label: '+593'
    },
    {
      countryName: 'Panamá',
      value: '+507',
      label: '+507'
    },
    {
      countryName: 'Paraguay',
      value: '+595',
      label: '+595'
    },
    {
      countryName: 'Uruguay',
      value: '+598',
      label: '+598'
    },
    {
      countryName: 'Venezuela',
      value: '+58',
      label: '+58'
    }
  ];
  
  export default countryCodes;
  
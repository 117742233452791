import React from 'react';
// eslint-disable-next-line no-unused-vars
import { CSS } from 'css.gg';

const IconGG = ({ icon, size = 1, ...props }) => {
  const { className, ...rest } = props;

  return (
      <i {...rest} className={`icon-size-${size} ${className} gg-${icon}`} />
  );
};

export default IconGG;

import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { authTypes, requestSignIn } from '../actions/auth';
import { utilsTypes } from '../actions/utils';
import API from '../services/api';
import runDefaultSaga, { updateJWT } from './default';
import { getToken } from '../config/firebase';

// SIGN IN
const signInRequest = params => API.post('/login', params);
function* signInSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers
      .get('Authorization')
      .split('Bearer ')[1];
    const userId = result.id;
    yield updateJWT(authorization);
    yield getToken();
    yield put({ type: authTypes.SIGN_IN_SUCCESS, result, response });
    yield put({ type: utilsTypes.SUBMIT_TOKEN_REQUEST, userId });
    yield put(push('/tasks'));
  }
}
function* signInFailureCallback() {
  yield put({
    type: authTypes.SIGN_IN_FAILURE
  });
}
export function* signIn(action) {
  yield runDefaultSaga(
    { request: signInRequest, params: action.params },
    signInSuccessCallback,
    signInFailureCallback
  );
}

// CREATE ACCOUNT
const signUpRequest = params => API.post('/signup', params);
function* signUpSuccessCallback(result, response, params) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: authTypes.SIGN_UP_SUCCESS, result, response });
    const user = params;
    delete user.password_confirmation;
    yield put(requestSignIn(user));
  }
}
function* signUpFailureCallback() {
  yield put({
    type: authTypes.SIGN_UP_FAILURE
  });
}
export function* signUp(action) {
  yield runDefaultSaga(
    { request: signUpRequest, params: action.params },
    signUpSuccessCallback,
    signUpFailureCallback
  );
}
// SIGN UP OMNIAUTH

const signUpRequestOmniauth = params => API.post('/omniauth_sign_up', params);

function* signUpOmniauthSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers
      .get('Authorization')
      .split('Bearer ')[1];
    const userId = result.id;
    yield updateJWT(authorization);
    yield getToken();
    yield put({ type: utilsTypes.SUBMIT_TOKEN_REQUEST, userId });
    yield put({ type: authTypes.SIGN_IN_SUCCESS, result, response });
    yield put(push('/tasks'));
  }
}
function* signUpOmniauthFailureCallback() {
  yield put({
    type: authTypes.SIGN_IN_FAILURE
  });
}

export function* signUpOmniauth(action) {
  yield runDefaultSaga(
    { request: signUpRequestOmniauth, params: action.params },
    signUpOmniauthSuccessCallback,
    signUpOmniauthFailureCallback
  );
}

// SIGN OUT
const signOutRequest = () => API.delete('/logout');
function* signOutSuccessCallback(result) {
  if (result.success) {
    yield localStorage.removeItem('jwt');
    yield localStorage.removeItem('deviceToken');
    yield localStorage.removeItem('hasBeenAlertedForPermissions');
    yield localStorage.removeItem('hasBeenAlertedAboutFinishingTask');
    yield localStorage.removeItem('urlOpened');
    yield put({ type: authTypes.SIGN_OUT_SUCCESS });
    yield put(push('/'));
  } else {
    throw new Error(result);
  }
}
function* signOutFailureCallback() {
  yield put({ type: authTypes.SIGN_OUT_FAILURE });
}
export function* signOut() {
  yield runDefaultSaga(
    { request: signOutRequest },
    signOutSuccessCallback,
    signOutFailureCallback
  );
}

// VALIDATE TOKENS
const validateTokenRequest = () => API.get('/me');
function* validateTokensSuccessCallback(result, response) {
  if (result.logged_in) {
    yield put({
      type: authTypes.VALIDATE_TOKEN_SUCCESS,
      result,
      response,
      user: result.user_info
    });
    yield put({
      type: utilsTypes.SET_UNREAD_NOTIFICATIONS,
      unreadNotifications: result.user_info.unread_notifications
    });
  } else {
    yield put({ type: authTypes.CLEAR_AUTH_INFO });
    yield put(push('/login'));
  }
}
function* validateTokensFailureCallback() {
  if (navigator.onLine) {
    yield put({ type: authTypes.CLEAR_AUTH_INFO });
    yield put(push('/login'));
  } else {
    console.log('Navigator offline');
  }
}
export function* validateToken() {
  yield runDefaultSaga(
    { request: validateTokenRequest },
    validateTokensSuccessCallback,
    validateTokensFailureCallback
  );
}

// DEFINE authSagas
export default function* authSagas() {
  yield takeEvery(authTypes.SIGN_IN_REQUEST, signIn);
  yield takeEvery(authTypes.SIGN_UP_REQUEST, signUp);
  yield takeEvery(authTypes.SIGN_UP_REQUEST_OMNIAUTH, signUpOmniauth);
  yield takeEvery(authTypes.SIGN_OUT_REQUEST, signOut);
  yield takeEvery(authTypes.VALIDATE_TOKEN_REQUEST, validateToken);
}

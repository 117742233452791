import React from 'react';
import { Carousel } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { LinkBtn } from '../../../components';

const TasksCarousel = ({ tasks: tasksGroups }) => {
  return (
    <Carousel className="h-100">
      {tasksGroups &&
        tasksGroups.map((tasksGroup, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Carousel.Item key={`task-group-${index}`} interval={10000}>
            <div className="task-cards">
              {tasksGroup.map(task => {
                const {
                  id,
                  name: taskName,
                  withVariablePayment,
                  parsedTaskPayment,
                  parsedTaskAddionalPayment,
                  campaignName,
                  campaignStartDate,
                  campaignEndDate
                } = camelCaseRecursive(task);
                return (
                  <TaskCard
                    key={id}
                    taskName={taskName}
                    withVariablePayment={withVariablePayment}
                    parsedTaskPayment={parsedTaskPayment}
                    parsedTaskAddionalPayment={parsedTaskAddionalPayment}
                    campaignName={campaignName}
                    campaignStartDate={campaignStartDate}
                    campaignEndDate={campaignEndDate}
                  />
                );
              })}
            </div>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

const TaskCard = ({
  campaignImage,
  taskName,
  parsedTaskPayment,
  parsedTaskAddionalPayment,
  withVariablePayment,
  campaignName,
  campaignStartDate,
  campaignEndDate
}) => (
  <div className="task-card">
    <div className="main">
      <div className="header">
        <div className="header--thumbnail">
          <img
            className="header--thumbnail__image"
            src={campaignImage || '/images/default.png'}
            alt="campaign logo"
          />
        </div>
        <div className="header--info">
          <p className="header--info__title">{taskName}</p>
          <div className="header--info__prices">
            <p className="price">{parsedTaskPayment}</p>
            {withVariablePayment && (
              <p className="variable">{parsedTaskAddionalPayment}</p>
            )}
          </div>
        </div>
      </div>
      <div className="campaign-info">
        <p className="campaign-info--title">{campaignName}</p>
        <p className="campaign-info--duration">
          <strong>Duración: </strong>
          {campaignStartDate} -{campaignEndDate}
        </p>
      </div>
    </div>
    <div className="application-buttons--primary">
      <LinkBtn to="/login" type="button" className="primary-btn big">
        POSTULARME
      </LinkBtn>
    </div>
  </div>
);
export default TasksCarousel;

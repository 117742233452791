import camelCaseRecursive from 'camelcase-keys-recursive';
import { utilsTypes } from '../actions/utils';

const initialState = {
  settings: {
    mainContactEmail: ''
  },
  slides: [],
  page: {
    body: null
  },
  alert: {
    show: false,
    title: '',
    kind: '',
    timeout: 3000,
    message: ''
  },
  ongoingRequest: {
    getSettings: false,
    getSlides: false,
    getPage: false,
    submitToken: false
  },
  navigationSettings: {
    page: 0,
    displayLength: 0,
    queries: {},
    currentTaskId: '',
    useSavedNavigationSettings: false
  },
  notifications: {
    unreadNotifications: 0,
    showingUnreadNotifications: false,
    hideNotificationList: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case utilsTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: true }
      };
    case utilsTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: camelCaseRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SLIDES_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: true }
      };
    case utilsTypes.GET_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.result,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_SLIDES_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: true },
        url: action.url
      };
    case utilsTypes.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.result,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.SUBMIT_TOKEN_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, submitToken: true }
      };
    case utilsTypes.SUBMIT_TOKEN_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, submitToken: false }
      };
    case utilsTypes.SUBMIT_TOKEN_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, submitToken: false }
      };
    case utilsTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          kind: action.kind,
          timeout: action.timeout || initialState.alert.timeout,
          message: action.message,
          title: action.title
        }
      };
    case utilsTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    case utilsTypes.SET_CURRENT_NAVIGATION_SETTINGS:
      return {
        ...state,
        navigationSettings: {
          page: action.page,
          displayLength: action.displayLength,
          currentTaskId: action.currentTaskId,
          queries: action.queries
        }
      };
    case utilsTypes.SET_STOP_USING_SAVED_NAVIGATION:
      return {
        ...state,
        navigationSettings: {
          ...state.navigationSettings,
          useSavedNavigationSettings: false
        }
      };
    case utilsTypes.SET_CURRENT_TASK_NAVIGATION:
      return {
        ...state,
        navigationSettings: {
          ...state.navigationSettings,
          useSavedNavigationSettings: true,
          currentTaskId: action.taskId
        }
      };
    case utilsTypes.SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unreadNotifications: action.unreadNotifications
        }
      };
    case utilsTypes.ADD_UNREAD_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unreadNotifications: state.notifications.unreadNotifications + 1
        }
      };
    case utilsTypes.SET_SHOWING_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          showingUnreadNotifications: action.payload
        }
      };
    case utilsTypes.HIDE_NOTIFICATION_LIST:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          hideNotificationList: action.payload
        }
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap'
import IconGG from '../../Icons/IconGG';
import './style.scss'

const BasicMessage = ({body, alertType}) => {

  const hide = () =>{
      const element = document.getElementById('custom-message')
      element.style.display = "none"
      localStorage.setItem(alertType, 'true');
  }

  return (
    <>
      <div className="show-hide" id="custom-message">
        <div id="right">
          <Button href="#" onClick={hide}>
            <IconGG className="none" icon="close-o" size="1" />
          </Button>
        </div>
        <div id="left">
          {body}
        </div>
      </div>
    </>
  );
};

BasicMessage.propTypes = {
  body: PropTypes.string,
  alertType: PropTypes.string
};

BasicMessage.defaultProps = {
  body: '',
  alertType: 'hasBeenAlertedAboutFinishingTask'
};

export default BasicMessage;

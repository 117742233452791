import React from 'react';
import { LinkBtn } from '..';

const ProfilePayment = ({userPayments = []}) => {

  const handleStatusClass = status =>{
    if(status === 'pending'){
      return 'user-payment-pending'
    // eslint-disable-next-line no-else-return
    } else if (status === 'approved'){
        return 'user-payment-approved'
    } else if (status === 'rejected'){
        return 'user-payment-rejected'
    } else {
        return 'user-payment-pending'
    }
  }

  return (
    <>
    <div>
        <h4 className='user-payment-header'>Ultimos pagos</h4>
        <div className="user-payment-list">
          {  
            userPayments.map((userPayment) => (
              <div key={userPayment.id} className='user-payment-row'>
                <div className='user-payment-row__title'>
                  <p className='user-payment-time'>{userPayment.createdAtDay}</p>
                </div>
                <div className='user-payment-row__body'>
                    <p className='user-payment-title'>{`Pago #${userPayment.id}`}</p>
                    <p className='user-payment-amount-title'>Monto: <span className='user-payment-amount'>{userPayment.parsedAmount}</span></p>
                    <p className='user-payment-status'>
                    Estado:  
                    <span 
                        id={userPayment.status} 
                        className={handleStatusClass(userPayment.status)}
                    >
                        {userPayment.translatedStatus}
                    </span>
                    </p>
                </div>
              </div>
            ))
          }
        </div>
    </div>
    <div className="go-to-payments">
      <LinkBtn className='big' to='/payments/history'>
        VER TODOS LOS PAGOS
      </LinkBtn>
    </div>
    </>
  )
}

export default ProfilePayment

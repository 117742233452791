import React from 'react';
import NotificationList from './NotificationList';

const NotificationView = ({
  notifications,
  handleIndexRequest,
  hasMore,
  isDarkMode
}) => {
  return (
    <>
      <div className={`notification-container ${isDarkMode ? 'dark' : ''}`}>
        <div className="notification-header">
          <p className="notification-header--title">Notificaciones</p>
        </div>
        <NotificationList
          notifications={notifications}
          handleIndexRequest={handleIndexRequest}
          hasMore={hasMore}
        />
      </div>
    </>
  );
};

export default NotificationView;

import ApiService from '../services/apiService';

export const showUserRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/users/show', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateUserRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', '/users/update', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteDocumentRequest = ({ dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', '/users/destroy_document', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const changePasswordRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', '/change_password', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const stopImpersonateAccountAdminRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('post', `/users/stop_impersonate`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const loginToTalentLmsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/users/login_user_to_talent_lms_course`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const talentLmsUserStatusRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/users/talent_lms_user_status`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

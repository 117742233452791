import React from 'react';
import { Carousel } from 'react-bootstrap';

const FeedbackCarousel = () => {
  return (
    <Carousel className="h-100 feedbacks-carousel">
      <Carousel.Item interval={10000}>
        <FeedbackCard />
      </Carousel.Item>
      <Carousel.Item interval={10000}>
        <FeedbackCard />
      </Carousel.Item>
      <Carousel.Item interval={10000}>
        <FeedbackCard />
      </Carousel.Item>
    </Carousel>
  );
};

const FeedbackCard = ({ feedback = {} }) => {
  const { userProfileImg } = feedback;
  return (
    <div className="feedback-container">
      <div className="feedback">
        <div className="feedback-photo">
          <img
            src={userProfileImg || '/images/default.png'}
            alt="feedback user"
          />
        </div>
        <div className="feedback-info">
          <p className="feedback-info--name">John Doe</p>
          <p className="feedback-info--address">Ciudad, Región</p>
          <p className="feedback-info--text">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry standard dummy text ever
            since the 1500s
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCarousel;

/* eslint-disable func-names */
import React from 'react';
import moment from 'moment';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import SessionMainView from '../Session/SessionMainView';
import MainLogo from '../../components/Utils/MainLogo';
import {
  FormikInput,
  LinkBtn,
  FormikSelect,
  FormikDatePicker,
  RegionCommuneSelect,
  InputSelect,
  PhoneWithCode
} from '../../components';
import { signUpRequest } from '../../actions/auth';
import {
  capitalizeFirstLetter,
  rutFormat,
  validRutInput,
  isValidRut
} from '../../services/utils';
import formOptions from '../Payment/paymentFormOptions';
import { debounceIndexCountriesRequest } from '../../requests/countries';

const currentCountry = process.env.REACT_APP_CURRENT_COUNTRY;
const isChilean = currentCountry === 'CL';
const isPeruvian = currentCountry === 'PE';

const Register = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    setFieldValue,
    values
  } = props;
  const dispatch = useDispatch();

  const handleFormatting = e => {
    const formattedValue = capitalizeFirstLetter(e.target.value);
    setFieldValue(e.target.name, formattedValue);
  };

  const {
    bankOptions,
    accountTypeOptions,
    currencyOptions,
    documentTypesOptions
  } = formOptions;

  const handleNationalIdentificationFormat = e => {
    if (isChilean && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  // Fetch Countries
  const fetchCountries = (inputValue, callback) => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        name: inputValue,
        display_length: 1000
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  return (
    <>
      <SessionMainView>
        <Row className="justify-content-center align-items-center vh-100">
          <MainLogo imageClass="session-main--logo" />
          <Col md={8} lg={7}>
            <Form className="session-form scrollable">
              <h2>Formulario de registro</h2>
              <Row className="d-flex justify-content-between">
                <Col className="p-0 pr-4">
                  <Field name="name">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Nombre"
                        placeholder="Tu nombre"
                        onChange={handleFormatting}
                        error={errors[field.name]}
                        touched={touched[field.name]}
                      />
                    )}
                  </Field>
                </Col>
                <Col className="p-0 pl-4">
                  <Field name="lastName">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Apellido"
                        placeholder="Tu apellido"
                        onChange={handleFormatting}
                        error={errors[field.name]}
                        touched={touched[field.name]}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Field name="email">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Correo"
                    placeholder="Email"
                    inputType="email"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    label="Contraseña"
                    placeholder="Contraseña"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Field name="passwordConfirmation">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    label="Confirmar Contraseña"
                    placeholder="Confirmar Contraseña"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <h3>Datos de personales</h3>
              <Field name="userDocumentNumber">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label={isChilean ? 'RUT' : 'Número de Documento'}
                    onChange={handleNationalIdentificationFormat}
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <PhoneWithCode
                countryCodeField="phoneCountryCode"
                abbr
                label="Teléfono"
                phoneField="phone"
                // phoneCountryCode={user.phoneCountryCode}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
              <Field name="birthdate">
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    isOutsideRange={day => moment().diff(day) < 0}
                    label="Fecha de Nacimiento"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
              <Field name="countryId">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="Nacionalidad"
                    placeholder="Seleccionar País"
                    values={values.user}
                    model={['', 'country']}
                    request={fetchCountries}
                    onChange={data =>
                      setFieldValue(field.name, data ? data.value : '')
                    }
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
              {isChilean && (
                <RegionCommuneSelect
                  regionAbbr
                  communeAbbr
                  modelKey=""
                  dispatch={dispatch}
                  currentModel=""
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
              <Field name="address">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Dirección"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
              <h3>Datos de transferencia</h3>
              <Row className="custom-gap">
                <Col md={6} sm={12} className="p-0 pr-4">
                  <Field name="bankDocumentNumber">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label={isChilean ? 'RUT' : 'Número de Documento'}
                        onChange={handleNationalIdentificationFormat}
                        error={errors[field.name]}
                        touched={touched[field.name]}
                      />
                    )}
                  </Field>
                </Col>

                <Col md={6} sm={12} className="p-0 pl-4">
                  <Field name="bank" style={{ heigth: '4rem' }}>
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Banco"
                        placeholder="Seleccionar"
                        options={bankOptions}
                        onChange={data =>
                          setFieldValue(field.name, data ? data.value : '')
                        }
                        error={errors[field.name]}
                        touched={touched[field.name]}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6} sm={12} className="p-0 pr-4">
                  <Field name="accountType">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Tipo de Cuenta"
                        placeholder="Seleccionar"
                        options={accountTypeOptions}
                        onChange={data =>
                          setFieldValue(field.name, data ? data.value : '')
                        }
                        error={errors[field.name]}
                        touched={touched[field.name]}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6} sm={12} className="p-0 pl-4">
                  <Field name="accountNumber">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        label="Número de Cuenta"
                        error={errors[field.name]}
                        touched={touched[field.name]}
                      />
                    )}
                  </Field>
                </Col>
                {isPeruvian && (
                  <>
                    <Col md={6} sm={12} className="p-0 pr-4">
                      <Field name="interbancaryNumber">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Número Interbancario"
                            error={errors[field.name]}
                            touched={touched[field.name]}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={6} sm={12} className="p-0 pl-4">
                      <Field name="documentType">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr
                            label="Tipo de Documento"
                            placeholder="Seleccionar"
                            options={documentTypesOptions}
                            onChange={data =>
                              setFieldValue(field.name, data ? data.value : '')
                            }
                            error={errors[field.name]}
                            touched={touched[field.name]}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={6} sm={12} className="p-0 pr-4">
                      <Field name="currency">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr
                            label="Moneda"
                            placeholder="Seleccionar"
                            options={currencyOptions}
                            onChange={data =>
                              setFieldValue(field.name, data ? data.value : '')
                            }
                            error={errors[field.name]}
                            touched={touched[field.name]}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
              <Button
                variant={submitVariant}
                size="lg"
                className="mt-4 big session-btn"
                block
                type="submit"
                onClick={onHide}
              >
                REGISTRARME
              </Button>
            </Form>
            <div className="session-links pb-4">
              <LinkBtn to="/login">
                ¿Ya tienes cuenta? <strong>Entra aquí</strong>
              </LinkBtn>
            </div>
          </Col>
        </Row>
      </SessionMainView>
    </>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  name: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  documentType: isChilean ? 'rut' : '',
  userDocumentNumber: '',
  bankDocumentNumber: '',
  accountNumber: '',
  interbancaryNumber: '',
  currentCountry: '',
  accountType: '',
  bank: '',
  currency: '',
  birthdate: '',
  address: '',
  phone: '',
  phoneCountryCode: '',
  countryId: '',
  communeId: '',
  regionId: ''
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Este campo es obligatorio'),
  lastName: Yup.string().required('Este campo es obligatorio'),
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Obligatorio'),
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test(
      'password-confirmation-test',
      'Las contraseñas no coinciden',
      function equalTo(value) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    ),
  documentType: Yup.string().when([], {
    is: () => isPeruvian,
    then: Yup.string().required('Este campo es obligatorio'),
    otherwise: Yup.string().nullable()
  }),
  userDocumentNumber: Yup.string().when('documentType', {
    is: 'rut',
    then: Yup.string()
      .required('Debes ingresar un RUT válido')
      .test('rut-validation-test', 'El RUT ingresado no es válido', function(
        value
      ) {
        return isValidRut(value);
      }),
    otherwise: Yup.string().required('Debes ingresar un número de documento')
  }),
  bankDocumentNumber: Yup.string().when('documentType', {
    is: 'rut',
    then: Yup.string()
      .required('Debes ingresar un RUT válido')
      .test('rut-validation-test', 'El RUT ingresado no es válido', function(
        value
      ) {
        return isValidRut(value);
      }),
    otherwise: Yup.string().required('Debes ingresar un número de documento')
  }),
  accountType: Yup.string().required('Debes ingresar el tipo de cuenta'),
  accountNumber: Yup.string().required('Debes ingresar el número de cuenta'),
  bank: Yup.string().required('Debes ingresar el banco'),
  currency: Yup.string().when('currentCountry', {
    is: () => isChilean,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required(
      'Debes indicar en qué moneda deseas recibir el pago'
    )
  }),
  interbancaryNumber: Yup.string().when('currentCountry', {
    is: () => isChilean,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Debes indicar tu número interbancario')
  }),
  birthdate: Yup.string().required('Debes ingresar una fecha de nacimiento'),
  countryId: Yup.string().required('Debes indicar tu nacionalidad'),
  regionId: Yup.string().when([], {
    is: () => isChilean,
    then: Yup.string().required('Debes ingresar Región'),
    otherwise: Yup.string().nullable()
  }),
  communeId: Yup.string().when([], {
    is: () => isChilean,
    then: Yup.string().required('Debes ingresar Comuna'),
    otherwise: Yup.string().nullable()
  }),
  phone: Yup.string()
    .required('Debes ingresar una número de teléfono')
    .when('phoneCountryCode', {
      is: val => val === '+56',
      then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
      otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
    })
    .nullable(),
  phoneCountryCode: Yup.string().required(
    'Debes seleccionar el código de pais'
  ),
  address: Yup.string()
    .required('Debes ingresar una dirección')
    .max(120, 'Deben ser menos que 120 caracteres')
    .test('is-alphanumeric', 'Deben ser caracteres alfanuméricos', value => {
      const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
      return alphanumericRegex.test(value);
    })
    .nullable()
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    signUpRequest({
      user: {
        name: values.name,
        first_last_name: values.lastName,
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        national_identification: values.userDocumentNumber,
        birthdate: values.birthdate,
        address: values.address,
        phone: values.phone,
        phone_country_code: values.phoneCountryCode,
        country_id: values.countryId,
        commune_id: values.communeId,
        region_id: values.regionId,
        bank_detail_attributes: {
          document_number: values.bankDocumentNumber,
          account_number: values.accountNumber,
          account_type: values.accountType,
          interbancary_number: values.interbancaryNumber,
          name: values.name,
          bank: values.bank,
          last_name: values.lastName,
          current_country: values.currentCountry,
          currency: values.currency,
          document_type: values.documentType
        }
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Register)
  )
);

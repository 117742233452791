import React from 'react';
import { Link } from 'react-router-dom';
import { Warning, LinkBtn } from '..';
import IconGG from '../Icons/IconGG';

const PaymentBankInfo = ({ areCompleted }) => {
  return (
    <div className="bank-info-container">
      {areCompleted ? (
        <div className="completed-info">
          <div className="completed-info--header">
            <p className="completed-info--header__title">Datos Bancarios</p>
            <Link to="/payments/my_details">
              <p className="completed-info--header__edit">
                <IconGG className="light-blue" icon="pen" size="1" />
                <span>Editar Datos</span>
              </p>
            </Link>
          </div>
          <p>Tus datos estan completados correctamente.</p>
        </div>
      ) : (
        <>
          <Warning
            title="Datos bancarios"
            warningText="Aún no has llenado estos datos, complétalos para solicitar tu monto acumulado."
          />
          <div className="task-buttons--primary mt-4">
            <LinkBtn
              to="/payments/my_details"
              className="primary-btn bg-warning-yellow big"
            >
              Llenar Datos
            </LinkBtn>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentBankInfo;

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { rutFormat, validRutInput } from '../../services/utils';
import '../../services/yupCustomMethods';
import { genderTypes, activities } from './FormOptions';
import { debounceIndexCountriesRequest } from '../../requests/countries';
import {
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  PhoneWithCode,
  InputSelect,
  RegionCommuneSelect,
  UploadImage,
  RadioButton
} from '../../components';

const ProfileForm = props => {
  const {
    onHide,
    submitVariant,
    user,
    errors,
    touched,
    setFieldValue,
    values,
    setFieldTouched
  } = props;

  const dispatch = useDispatch();
  const currentCountry = process.env.REACT_APP_CURRENT_COUNTRY;
  const { currentActivity } = values.user;

  const handleNationalIdentificationFormat = (e, isRut) => {
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  // Fetch Countries
  const fetchCountries = (inputValue, callback) => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        name: inputValue,
        display_length: 1000
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  return (
    <Form>
      <Row>
        <Col md={12}>
          <h4>Datos Personales</h4>
          <div className="profile-avatar">
            <Field name="user[avatar]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  name="Modificar avatar"
                  customClass="d-none d-sm-flex"
                  imageUrl={getIn(user.avatar, 'fileUrl')}
                  onChange={_avatar => {
                    setFieldValue(field.name, _avatar);
                  }}
                  error={getIn(errors, field.name)}
                  onTop
                  touched={getIn(touched, field.name)}
                  helpText="Formato sugerido 620x400px de máximo 10mb."
                />
              )}
            </Field>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="user[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="user[firstLastName]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Apellido Paterno"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="user[secondLastName]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Apellido Materno"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="user[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={currentCountry === 'CL' ? 'RUT' : 'Nº de Identificación'}
                onChange={e =>
                  handleNationalIdentificationFormat(e, currentCountry === 'CL')
                }
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="user[gender]">
            {({ field }) => (
              <RadioButton
                abbr
                defaultValue={values.user?.gender}
                options={genderTypes}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                onChange={e => setFieldValue('user[gender]', e.target.value)}
                label="Sexo"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="user[birthdate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={day => moment().diff(day) < 0}
                label="Fecha de Nacimiento"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <PhoneWithCode
            countryCodeField="user[phoneCountryCode]"
            abbr
            label="Teléfono"
            phoneField="user[phone]"
            phoneCountryCode={user.phoneCountryCode}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row className="form-group">
        <Col md={6} sm={12}>
          <Field name="user[countryId]">
            {({ field }) => (
              <InputSelect
                {...field}
                abbr
                label="Nacionalidad"
                placeholder="Seleccionar País"
                values={values.user}
                model={[user, 'country']}
                request={fetchCountries}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <RegionCommuneSelect
            regionAbbr
            communeAbbr
            modelKey="user"
            dispatch={dispatch}
            currentModel={user}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="user[address]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <h4 className="mt-3">Laboral</h4>
      <Row>
        <Col md={6} sm={12}>
          <Field name="user[currentActivity]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Situación Actual"
                placeholder="Seleccionar"
                options={activities}
                defaultValue={user?.currentActivity}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        {currentActivity === 'student' && (
          <>
            <Col md={6} sm={12}>
              <Field name="user[university]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Universidad"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} sm={12}>
              <Field name="user[career]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Carrera"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={6} sm={12} className="mt-4">
          <Button
            type="submit"
            variant={submitVariant}
            size="lg"
            onClick={onHide}
            className="w-100"
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { user } = props;
  const {
    avatar,
    name,
    email,
    firstLastName,
    secondLastName,
    id,
    nationalIdentification,
    birthdate,
    gender,
    address,
    phone,
    phoneCountryCode,
    currentActivity,
    university,
    career,
    countryId,
    regionId,
    communeId
  } = user;
  return {
    user: {
      avatar: avatar || {},
      name: name || '',
      email: email || '',
      firstLastName: firstLastName || '',
      secondLastName: secondLastName || '',
      id: id || '',
      nationalIdentification: nationalIdentification || '',
      birthdate: birthdate || '',
      gender: gender || '',
      address: address || '',
      phone: phone || '',
      phoneCountryCode: phoneCountryCode || '',
      currentActivity: currentActivity || '',
      university: university || '',
      career: career || '',
      countryId: countryId || '',
      regionId: regionId || '',
      communeId: communeId || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
      .nullable(),
    firstLastName: Yup.string()
      .required('Debes ingresar un apellido paterno')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
      .nullable(),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un número de identificación')
      .when('currentCountry', (currentCountry, schema) =>
        currentCountry === 'CL'
          ? schema.rut('Debe ingresar un RUT válido')
          : schema.alphanumeric('Deben ser caracteres alfanuméricos')
      )
      .nullable(),
    address: Yup.string()
      .required('Debes ingresar una dirección')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
      .nullable(),
    birthdate: Yup.string().required('Debes ingresar una fecha de nacimiento'),
    countryId: Yup.string().required('Debes indicar tu nacionalidad'),
    regionId: Yup.string().required('Debes ingresar Región'),
    communeId: Yup.string().required('Debes ingresar Comuna'),
    gender: Yup.string().required('Debes seleccionar tu género'),
    phone: Yup.string()
      .required('Debes ingresar una número de teléfono')
      .when('phoneCountryCode', {
        is: val => val === '+56',
        then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
        otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
      })
      .nullable(),
    phoneCountryCode: Yup.string().required(
      'Debes seleccionar el código de pais'
    ),
    secondLastName: Yup.string()
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos')
      .nullable(),
    email: Yup.string().required('Debes ingresar un email'),
    currentActivity: Yup.string().required('Debes ingresar tu situación actual')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  const updatedValues = values;
  updatedValues.user.documentCode = props.documentCode;
  formRequest(updatedValues, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: true
})(ProfileForm);

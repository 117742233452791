import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { NavbarTop } from '../components';
import Sidebar from '../components/Utils/Sidebar/Sidebar';

export const LoggedLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <Sidebar />
            <div className="content-body">
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('jwt') ? (
        <Redirect
          to={{ pathname: '/tasks', state: { from: props.location } }}
        />
      ) : (
        <>
          <Component {...props} />
        </>
      )
    }
  />
);

export const PublicLayout = ({ component: Component, ...rest }) => {
  const {
    location: { pathname }
  } = rest;
  const isRootRoute = pathname === '/';

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            {isRootRoute ? (
              <Redirect
                to={{ pathname: '/tasks', state: { from: props.location } }}
              />
            ) : (
              <>
                <Sidebar />
                <div className="content-body">
                  <Component {...props} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <NavbarTop />
            <div className="content-public">
              <Component {...props} />
            </div>
          </>
        )
      }
    />
  );
};

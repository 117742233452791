import React from 'react';
import { Image } from 'react-bootstrap';
import Logo from './logo-treid-azul-c.svg';
import DarkLogo from './logo-treid-azul-o.svg';

const MainLogo = ({ imageClass, darkMode = false }) => (
  <Image
    src={darkMode ? Logo : DarkLogo}
    alt="Treid Logo"
    className={`d-block mx-auto w-100 ${imageClass}`}
  />
);

export default MainLogo;

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import SessionMainView from '../Session/SessionMainView';
import MainLogo from '../../components/Utils/MainLogo';
import { FormikInput, LinkBtn } from '../../components';
import { sendAlert } from '../../actions/utils';
import { forgotPasswordRequest } from '../../requests/utils';

const RecoverPassword = props => {
  const { onHide, submitVariant, errors, touched } = props;

  return (
    <SessionMainView>
      <Row className="justify-content-center align-items-center vh-100 align-content-center">
        <MainLogo imageClass="session-main--logo m-0" />
        <Col md={5}>
          <Form className="session-form">
            <Field name="email">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Correo"
                  placeholder="Email"
                  inputType="email"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
            <Button
              variant={submitVariant}
              size="lg"
              className="mt-4 big session-btn"
              block
              type="submit"
              onClick={onHide}
            >
              RECUPERAR CONTRASEÑA
            </Button>
          </Form>
          <div className="session-links">
            <LinkBtn to="/login">
              ¿Ya tienes cuenta? <strong>Inicia sesión aquí</strong>
            </LinkBtn>
            <LinkBtn to="/register">
              ¿No tienes cuenta? <strong>Crea una aquí</strong>
            </LinkBtn>
          </div>
        </Col>
      </Row>
    </SessionMainView>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch, history } = props;
  forgotPasswordRequest({
    dispatch,
    params: {
      email: values.email
    },
    successCallback: result => {
      history.push('/login');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPassword)
  )
);

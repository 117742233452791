import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';

const RemoteSelect = ({ value, request, onChange, disabled, ...moreProps }) => {
  const [isOpen, setIsOpen] = useState(false);

  const colourStyles = {
    control: base => ({
      ...base,
      fontSize: '1.4rem',
      backgroundColor: 'white',
      borderRadius: '1rem',
      minHeight: '32px',
      height: '33px'
    }),
    menu: base => ({
      ...base,
      fontSize: '1.4rem',
      backgroundColor: 'white'
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#ACE1DB' : null,
        color: '#333333',
        textAlign: 'start'
      };
    }
  };
  return (
    <AsyncSelect
      className={`${isOpen ? 'border-on ' : ''}`}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      placeholder="Buscar..."
      loadingMessage={() => 'Cargando...'}
      noOptionsMessage={() => 'No hay opciones'}
      value={value}
      defaultOptions
      loadOptions={request}
      onChange={onChange}
      isDisabled={disabled}
      {...moreProps}
      styles={colourStyles}
    />
  );
};

export default RemoteSelect;

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { setInfoUser } from '../actions/auth';
import { showUserRequest, updateUserRequest } from '../requests/user';
import { createUserPaymentRequest } from '../requests/user_payments';
import { sendAlert } from '../actions/utils';

const usePaymentRequests = () => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureGetUserInfoRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al buscar tu información. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const handleSuccessGetUserInfoRequest = response => {
    setUser(camelCaseRecursive(response.data));
    dispatch(setInfoUser(response.data));
  };

  const getUserInfo = () => {
    showUserRequest({
      dispatch,
      successCallback: handleSuccessGetUserInfoRequest,
      failureCallback: handleFailureGetUserInfoRequest
    });
  };

  const handleFailureUpdateRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al guardar tus datos bancarios. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const handleSuccessUpdateRequest = response => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Datos bancarios actualizados correctamente'
      })
    );
    dispatch(setInfoUser(response.data));
    history.push('/payments');
  };

  const handleUpdateRequest = params => {
    const sendParams = snakeCaseKeys(params);
    updateUserRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdateRequest,
      failureCallback: handleFailureUpdateRequest
    });
  };

  const handleFailureCreateUserPaymentRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al solicitar tu pago. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const handleSuccessCreateUserPaymentRequest = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Pago solicitado correctamente'
      })
    );
    history.push('/payments/history');
  };

  const handleCreateUserPaymentRequest = params => {
    const { receipt } = params.userPayment;
    const sendParams = snakeCaseKeys(params);
    sendParams.user_payment.receipt = receipt;

    createUserPaymentRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreateUserPaymentRequest,
      failureCallback: handleFailureCreateUserPaymentRequest
    });
  };

  useEffect(getUserInfo, []);

  return { user, handleUpdateRequest, handleCreateUserPaymentRequest };
};

export default usePaymentRequests;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signUpRequestOmniauth } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { parseUrlParams } from '../../services/utils';

const AppleHandler = ({ location }) => {
  const dispatch = useDispatch();
  const { search: urlSearch } = location;
  const { code, id_token: idToken, first_name: firstName, last_name: lastName } = parseUrlParams(urlSearch);

  const handleSuccessRequest = () => {
    const response = 'Inicio de sesión correcto';
    dispatch(sendAlert({ kind: 'success', response }));
  };

  const handleFailureRequest = response => {
    dispatch(sendAlert({ kind: 'error', response }));
  };

  const handleLoginWithApple = () => {
    const myParams = {
      id_token: idToken,
      code,
      user_data: {
          firstName,
          lastName
      },
      from_apple: true
    };
    dispatch(
      signUpRequestOmniauth({
        user: myParams,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest
      })
    );
  };

  useEffect(handleLoginWithApple, []);

  return (<></>)
}

export default AppleHandler

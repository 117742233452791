import React from 'react';
import { Alert } from 'react-bootstrap';

const MapAlert = ({ variant = '', showZoomAlert }) => {
  const alertMessage = 'Haz zoom en el mapa para encontrar resultados';

  return (
    <div className={`alert-container ${showZoomAlert ? 'show' : ''}`}>
      <Alert variant={variant}>
        <p className="alert-message">{alertMessage}</p>
      </Alert>
    </div>
  );
};

export default MapAlert;

import React from 'react';
import IconGG from '../Icons/IconGG';

const CanApplyMessage = props => {
  const {title, body, icon} = props

  return (
    <div className="apply-message">
      <div className="apply-message--title">
        <IconGG className={icon === 'danger' ? "yellow" : "green"} icon={icon} size="1" />
        <p className="ml-3">{title}</p>
      </div>
      <div className="apply-message--body">
        {body}
      </div>
    </div>
  );
};

export default CanApplyMessage;

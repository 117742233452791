import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexShiftUsersRequest } from '../requests/shift_users';

import { sendAlert } from '../actions/utils';

const useFetchShiftUsers = () => {
  const [shiftUsers, setShiftUsers] = useState([]);
  const [moreShiftUsers, setMoreShiftUsers] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const standardDisplayLength = 20;

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    const responseShiftUsers = response.data.data;
    const totalAmountOfData = response.data.metadata.amount;
    setIsFetching(false);
    setShiftUsers([...shiftUsers, ...camelCaseRecursive(responseShiftUsers)]);
    setHasMore(shiftUsers.length < totalAmountOfData);
    setPage(page + 1);
  };

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al buscar tus turnos. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setIsFetching(false);
  };

  const handleIndexRequest = params => {
    const sendParams = {
      display_length: standardDisplayLength,
      display_start: page * standardDisplayLength,
      sort_column: 'created_at',
      sort_direction: 'desc',
      job_status: 'validated',
      ...params
    };
    debounceIndexShiftUsersRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
    setIsFetching(true);
  };

  const reFetchShiftUsers = () => {
    setShiftUsers([]);
    setPage(0);
    setMoreShiftUsers(m => !m);
  };

  useEffect(handleIndexRequest, [moreShiftUsers]);

  return {
    shiftUsers,
    hasMore,
    isFetching,
    handleIndexRequest,
    reFetchShiftUsers
  };
};

export default useFetchShiftUsers;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import {
  debounceIndexNotificationsRequest,
  readAllNotificationsRequest
} from '../requests/notifications';

import { sendAlert, setUnreadNotifications } from '../actions/utils';

const useFetchNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [moreNotifications, setMoreNotifications] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { unreadNotifications } = useSelector(
    state => state.utils.notifications
  );
  const standardDisplayLength = 20;

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    const responseNotifications = response.data.data;
    const totalAmountOfData = response.data.metadata.amount;
    setIsFetching(false);
    setNotifications([
      ...notifications,
      ...camelCaseRecursive(responseNotifications)
    ]);
    setHasMore(notifications.length < totalAmountOfData);
    setPage(page + 1);
  };

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al buscar tus notificationes. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setIsFetching(false);
  };

  const handleIndexRequest = params => {
    const sendParams = {
      display_length: standardDisplayLength,
      display_start: page * standardDisplayLength,
      sort_column: 'created_at',
      sort_direction: 'desc',
      ...params
    };
    debounceIndexNotificationsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
    setIsFetching(true);
  };

  const reFetchNotifications = () => {
    setNotifications([]);
    setPage(0);
    setMoreNotifications(m => !m);
  };

  const handleUnreadNotifications = () => {
    if (!unreadNotifications) return;
    reFetchNotifications();
  };

  const handleSuccessReadAllRequest = () => {
    dispatch(setUnreadNotifications(0));
  };

  const handleReadAllRequest = () => {
    readAllNotificationsRequest({
      dispatch,
      successCallback: handleSuccessReadAllRequest,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(handleIndexRequest, [moreNotifications]);
  useEffect(handleUnreadNotifications, [unreadNotifications]);

  return {
    notifications,
    hasMore,
    isFetching,
    handleIndexRequest,
    handleReadAllRequest,
    reFetchNotifications
  };
};

export default useFetchNotifications;

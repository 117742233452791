import React from 'react';
import UserShift from './UserShift';
import InfiniteScroll from '../InfiniteScroll';
import { SpinnerLoader, NoResults } from '..';

const PaymentList = ({
  userShifts,
  handleIndexRequest,
  hasMore,
  isFetching,
  noTitle
}) => {
  return (
    <>
      {!noTitle && <h4 className="mb-3">Turnos validados</h4>}
      <div id="payment-list" className="payment-list">
        {isFetching && <SpinnerLoader />}
        {!isFetching && userShifts.length === 0 ? (
          <NoResults text="¡Aún no tienes ningún turno validado!" />
        ) : (
          <InfiniteScroll
            dataLength={userShifts.length}
            next={handleIndexRequest}
            hasMore={hasMore}
            label="turnos"
            scrollableTarget="payment-list"
          >
            {userShifts.map(userShift => {
              const { id } = userShift;
              return <UserShift userShift={userShift} key={id} />;
            })}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default PaymentList;

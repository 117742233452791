import React from 'react';
import IconGG from '../../Icons/IconGG';

const Warning = ({ title, warningText }) => {
  return (
    <div className="warning">
      <div className="warning--title d-flex align-items-center mb-3">
        <IconGG className="yellow" icon="danger" size="1" />
        <p className="ml-3 font-weight-bold">{title} </p>
      </div>
      <div className="warning--body">{warningText}</div>
    </div>
  );
};

export default Warning;

import ApiService from '../services/apiService';

export const createDeviceRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/devices', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const deleteDeviceRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('delete', `/devices/destroy_by_token`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

import React, { useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { validateToken, requestSignOut } from '../../../actions/auth';
import PrincipalNavbar from './PrincipalNavbar';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';
import useNotifications from '../../../services/notifications';
import { deleteDeviceRequest } from '../../../requests/devices';

const NavbarLogged = () => {
  const { user } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const updateAbilityRoles = () => {
    updateAbility(ability, user.role);
  };

  const handleInit = () => {
    if (user.id === 0) {
      dispatch(validateToken());
    }
  };

  const handleLogout = () => {
    const sessionDeviceToken = localStorage.getItem('deviceToken');
    if (!sessionDeviceToken) {
      return dispatch(requestSignOut());
    }
    return deleteDeviceRequest({
      dispatch,
      params: { device: { token: sessionDeviceToken } },
      formData: true,
      successCallback: () => dispatch(requestSignOut()),
      failureCallback: () => dispatch(requestSignOut())
    });
  };

  useEffect(updateAbilityRoles, [user]);
  useEffect(handleInit, []);

  useNotifications();

  return (
    <PrincipalNavbar>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/tasks">
            Tareas
          </Nav.Link>
          <Nav.Link as={Link} to="/profile">
            Perfil
          </Nav.Link>
          {/* <Nav.Link as={Link} to="/home">
            Postulaciones
          </Nav.Link> */}
          <Nav.Link as={Link} to="/home">
            Pagos
          </Nav.Link>
          <Nav.Link onClick={handleLogout}>Cerrar Sesión</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </PrincipalNavbar>
  );
};

const mapStateToProps = state => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps)(NavbarLogged);

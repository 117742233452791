import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import { rutFormat, validRutInput } from '../../services/utils';
import '../../services/yupCustomMethods';
import formOptions from './paymentFormOptions';

import { FormikInput, FormikSelect } from '../../components';

const PaymentDetailsForm = ({
  onHide,
  submitVariant,
  errors,
  touched,
  setFieldValue,
  user,
  currentCountry
}) => {
  const modelName = 'user[bankDetailAttributes]';
  const {
    bankOptions,
    accountTypeOptions,
    currencyOptions,
    documentTypesOptions
  } = formOptions;

  const isChilean = currentCountry === 'CL';
  const isPeruvian = currentCountry === 'PE';

  const handleNationalIdentificationFormat = e => {
    if (isChilean && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  return (
    <Form>
      <Row>
        <Col md={6} sm={12}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name={`${modelName}[lastName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {!isChilean && (
          <Col md={6} sm={12}>
            <Field name={`${modelName}[documentType]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Documento"
                  placeholder="Seleccionar"
                  options={documentTypesOptions}
                  defaultValue={user?.bankDetailAttributes?.documentType || ''}
                  onChange={data =>
                    setFieldValue(field.name, data ? data.value : '')
                  }
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        <Col md={6} sm={12}>
          <Field name={`${modelName}[documentNumber]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={isChilean ? 'RUT' : 'Número de Documento'}
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col md={6} sm={12}>
          <Field name={`${modelName}[bank]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Banco"
                placeholder="Seleccionar"
                options={bankOptions}
                defaultValue={user?.bankDetailAttributes?.bank || ''}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name={`${modelName}[accountType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Cuenta"
                placeholder="Seleccionar"
                options={accountTypeOptions}
                defaultValue={user?.bankDetailAttributes?.accountType || ''}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name={`${modelName}[accountNumber]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Número de Cuenta"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {isPeruvian && (
          <Col md={6} sm={12}>
            <Field name={`${modelName}[interbancaryNumber]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Número Interbancario"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {!isChilean && (
          <Col md={6} sm={12}>
            <Field name={`${modelName}[currency]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Moneda"
                  placeholder="Seleccionar"
                  options={currencyOptions}
                  defaultValue={user?.bankDetailAttributes?.currency || ''}
                  onChange={data =>
                    setFieldValue(field.name, data ? data.value : '')
                  }
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={6} sm={12} className="mt-4">
          <Button
            type="submit"
            variant={submitVariant}
            size="lg"
            onClick={onHide}
            className="w-100 big"
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ user, currentCountry }) => {
  const {
    bankDetailAttributes = {},
    nationalIdentification,
    name,
    firstLastName
  } = user;

  return {
    user: {
      nationalIdentification,
      bankDetailAttributes: {
        ...bankDetailAttributes,
        documentNumber:
          bankDetailAttributes?.documentNumber || nationalIdentification || '',
        accountNumber: bankDetailAttributes?.accountNumber || '',
        interbancaryNumber: bankDetailAttributes?.interbancaryNumber || '',
        name: bankDetailAttributes?.name || name || '',
        lastName: bankDetailAttributes?.lastName || firstLastName || '',
        currentCountry
      }
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    bankDetailAttributes: Yup.object().shape({
      name: Yup.string().required(
        'Debes ingresar el nombre de la persona que recibirá la transferencia'
      ),
      lastName: Yup.string().required(
        'Debes ingresar el apellido de la persona que recibirá la transferencia'
      ),
      documentType: Yup.string().nullable(),
      documentNumber: Yup.string().when('documentType', {
        is: 'rut',
        then: Yup.string()
          .required('Debes ingresar un RUT')
          .rut('El RUT es inválido'),
        otherwise: Yup.string().required(
          'Debes ingresar un número de documento'
        )
      }),
      accountType: Yup.string().required('Debes ingresar el tipo de cuenta'),
      accountNumber: Yup.string().required(
        'Debes ingresar el número de cuenta'
      ),
      bank: Yup.string().required('Debes ingresar el banco'),
      currency: Yup.string().when('currentCountry', {
        is: 'CL',
        then: Yup.string().nullable(),
        otherwise: Yup.string().required(
          'Debes indicar en qué moneda deseas recibir el pago'
        )
      }),
      interbancaryNumber: Yup.string().when('currentCountry', {
        is: 'CL',
        then: Yup.string().nullable(),
        otherwise: Yup.string().required(
          'Debes indicar tu número interbancario'
        )
      })
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, currentCountry } = props;
  const correctedValues = { ...values };

  if (currentCountry === 'CL') {
    correctedValues.user.bankDetailAttributes.currency = 'clp';
    correctedValues.user.bankDetailAttributes.documentType = 'rut';
  }
  formRequest(correctedValues);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: true
})(PaymentDetailsForm);

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Eye, Edit, Trash } from 'react-feather';
import { ButtonTooltip } from '../Utils/Tooltips';

const BasicActionBtns = ({
  item,
  path,
  clickHandler,
  showClick,
  editClick,
  destroyText,
  withShow,
  withEdit,
  withDestroy
}) => (
  <>
    {showClick ? (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'show')}
        variant="outline-info"
        className="btn-circle"
        size="sm"
        text="Ver"
      >
        <Eye width="15px" height="15px" />
      </ButtonTooltip>
    ) : (
      withShow && (
        <ButtonTooltip
          to={`/${path}/${item.id}`}
          variant="outline-info"
          className="btn-circle"
          size="sm"
          text="Ver"
          as={Link}
        >
          <Eye width="15px" height="15px" />
        </ButtonTooltip>
      )
    )}
    {}
    {editClick ? (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'edit')}
        variant="outline-warning"
        className="btn-circle"
        size="sm"
        text="Editar"
      >
        <Edit width="15px" height="15px" />
      </ButtonTooltip>
    ) : (
      withEdit && (
        <ButtonTooltip
          to={`/${path}/${item.id}/edit`}
          variant="outline-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
          as={Link}
        >
          <Edit width="15px" height="15px" />
        </ButtonTooltip>
      )
    )}
    {withDestroy && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'remove')}
        variant="outline-danger"
        className="btn-circle"
        size="sm"
        text={destroyText}
      >
        <Trash width="15px" height="15px" />
      </ButtonTooltip>
    )}
  </>
);

BasicActionBtns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  path: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  destroyText: PropTypes.string,
  editClick: PropTypes.bool,
  showClick: PropTypes.bool,
  withShow: PropTypes.bool,
  withEdit: PropTypes.bool,
  withDestroy: PropTypes.bool
};

BasicActionBtns.defaultProps = {
  destroyText: 'Eliminar',
  path: '',
  editClick: false,
  showClick: false,
  withShow: true,
  withEdit: true,
  withDestroy: true
};

export default BasicActionBtns;

import React from 'react';
import IconGG from '../../Icons/IconGG';

const IconedButton = ({
  buttonHandler,
  buttonClass = '',
  children,
  icon,
  iconClass = 'white',
  iconSize = 1
}) => {
  return (
    <button
      type="button"
      className={`iconed-btn ${buttonClass}`}
      onClick={buttonHandler}
    >
      <IconGG className={iconClass} icon={icon} size={iconSize} />
      {children}
    </button>
  );
};

export default IconedButton;

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexShiftUsersRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/shift_users', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const dayEventsShiftUsersRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/shift_users/day_events', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateShiftUserRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/shift_users/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexShiftUsersRequest = AwesomeDebouncePromise(
  indexShiftUsersRequest,
  300
);

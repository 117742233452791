import React from 'react';

const ShiftItem = ({ name, period, startDate, startDateTime, weekday }) => {
  return (
    <div className="shift-item">
      <span>{startDate}</span>
      <p className="m-0">
        {weekday}-{period}
      </p>
    </div>
  );
};

export default ShiftItem;

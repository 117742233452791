import React from 'react';
import IconGG from '../../../components/Icons/IconGG';

const HowItWorksSection = () => {
  return (
    <section id="how-it-works" className="landing--how-it-works">
      <p className="landing--how-it-works--title section-title">
        ¿Cómo funciona?
      </p>
      <div className="landing--how-it-works--cards">
        <HowItWorksCard
          title="Completa tu perfil"
          subtitle="Cuéntanos cuáles son tus habilidades y experiencias."
          icon="clipboard"
        />
        <HowItWorksCard
          title="Postula"
          subtitle="Entra a la app, busca las tareas que se ajusten a tu disponibilidad y preferencias.
            Capacitate y espera la confirmación."
          icon="flag-alt"
        />
        <HowItWorksCard
          title="¡Trabaja!"
          subtitle="Espera la confirmación y ¡manos a la obra!"
          icon="trophy"
        />
        <HowItWorksCard
          title="Recibe tu pago"
          subtitle="Puedes cobrar por tus tareas realizadas después de ser validadas por nuestro staff."
          icon="smile-mouth-open"
        />
      </div>
    </section>
  );
};

const HowItWorksCard = ({ title, subtitle, icon }) => (
  <div className="card">
    <div className="card-icon">
      <IconGG className="blue" icon={icon} size="2" />
    </div>
    <div className="card-body">
      <p className="card--title">{title}</p>
      <p className="card--subtitle">{subtitle}</p>
    </div>
  </div>
);

export default HowItWorksSection;

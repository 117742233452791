import React from 'react';
import Notification from './Notification';
import InfiniteScroll from '../InfiniteScroll';

const NotificationList = ({ notifications, handleIndexRequest, hasMore }) => {
  return (
    <div id="notification-list" className="notification-list">
      <InfiniteScroll
        dataLength={notifications.length}
        next={handleIndexRequest}
        hasMore={hasMore}
        label="notificationes"
        scrollableTarget="notification-list"
      >
        {notifications.map(notification => {
          const { id } = notification;
          return <Notification notification={notification} key={id} />;
        })}
      </InfiniteScroll>
    </div>
  );
};

export default NotificationList;

import React from 'react';

const SessionMainView = ({ children }) => {
  return (
    <div className="session-main">
      <div className="session-main--image">
        <img src="/images/people.png" alt="people" />
      </div>
      <div className="session-main--content">{children}</div>
      <span className="phone-container">
        <img src="/images/phone-treid.webp" alt="phone" />
      </span>
    </div>
  );
};

export default SessionMainView;

import React from 'react';
import { BackButton } from '../../components/ActionBtns';
import { UserPaymentList } from '../../components/Payment';
import { useFetchUserPayments } from '../../hooks';
import { groupBy } from '../../services/utils';

const UserPaymentIndex = () => {
  const {
    userPayments,
    handleIndexRequest,
    hasMore,
    isFetching
  } = useFetchUserPayments();

  return (
    <div>
      <h3>Historial de Pagos</h3>
      <BackButton to="/payments" />
      <UserPaymentList
        userPaymentsGroups={groupBy(userPayments, 'createdAtDay')}
        handleIndexRequest={handleIndexRequest}
        hasMore={hasMore}
        isFetching={isFetching}
      />
    </div>
  );
};

export default UserPaymentIndex;

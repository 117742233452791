import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { SimpleCenteredModal, IconGG, Rating } from '../index';
import ChangePasswordForm from '../../screens/Auth/ChangePasswordForm';
import { changePasswordRequest } from '../../requests/user';
import { sendAlert } from '../../actions/utils';

const ProfileHeader = ({user}) => {
  const {
      avatar = [],
      fullName = 'Debe completar su perfil',
      rating = 0.0,
      translatedLevel,
      parsedValidatedAmount
  } = user;
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };
    
  const handleSuccess = () =>{
    handleModalClose();
    const message = 'Contraseña modificada con éxito';
    dispatch(sendAlert({ kind: 'success', message }));
  }
    
  const handleRequestPassword = (values) =>{
    changePasswordRequest({
      dispatch,
      params: snakeCaseKeys(values),
      successCallback: handleSuccess,
    });
  }
     
  const handleChangePassword = () => {
    setModalShow(true);
    setModalBody(
      <ChangePasswordForm formRequest={handleRequestPassword}/>
    );
    setModalTitle('Cambiar Contraseña');
  };

  return (
    <>
      <div className="profile-top--content">
        <div className="profile-img--container">
          {avatar.fileUrl ? (
            <div className="profile-img">
              <Image src={avatar.fileUrl} fluid />
            </div>
          ) : (
            <div className="profile-img-dummy">
              <div>
                <IconGG icon="profile" size="3" />
              </div>
            </div>
          )}
        </div>
        <div className="profile-info">
          <div className="profile-data">
            <p>{fullName}</p>
            <Rating value={rating} text={rating} />
          </div>
          <div className="profile-edit">
            <Link to="/profile/edit">
              <div className="profile-edit-link">
                <IconGG icon="pen" size="1" />
                <p>Editar Perfil</p>
              </div>
            </Link>
            <div className="profile-edit-link">
              <button type="button" onClick={handleChangePassword} className="password">Cambiar Contraseña</button>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-data-level">
        <Link to="/">
          <span>Tu nivel actual es: {translatedLevel}</span>
        </Link>
      </div>
      <div className="profile-payment">
        <p className="profile-payment__title">Monto validado acumulado</p>
        <p className="profile-payment__price">{parsedValidatedAmount}</p>
      </div>
      <div className="profile-inverse-corner" />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        size="md"
        className="password-modal"
        closeButton={false}
        handleOnIconClick={handleModalClose}
      />
    </>
  )
}

export default ProfileHeader

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexTasksRequest } from '../requests/tasks';
import {
  sendAlert,
  setNavigationSettings,
  setStopUsingNavigationSettings
} from '../actions/utils';
import useGetUrlParams from './useGetUrlParams';

const useFetchTasks = queries => {
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { type } = useGetUrlParams();
  const { navigationSettings } = useSelector(state => state.utils);
  const {
    page: savedPage,
    displayLength: savedDisplayedLength,
    queries: savedQueries,
    useSavedNavigationSettings
  } = navigationSettings;

  const fromMap = type === 'map';
  const standardDisplayLength = fromMap ? 500 : 10;

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    const responseTasks = response.data.data;
    const totalAmountOfData = response.data.metadata.amount;
    setIsFetching(false);
    if (useSavedNavigationSettings) {
      setTasks(camelCaseRecursive(responseTasks));
      setHasMore(responseTasks.length < totalAmountOfData);
      setPage(savedPage + 1);
      dispatch(setStopUsingNavigationSettings());
    } else {
      const allTasks = [...tasks, ...camelCaseRecursive(responseTasks)];
      const nonRepetedTasks = Array.from(
        new Set(allTasks.map(task => task.id))
      ).map(id => allTasks.find(task => task.id === id));
      setTasks(nonRepetedTasks);
      setHasMore(tasks.length < totalAmountOfData);
      setPage(page + 1);
    }
  };

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', error: error?.response?.data?.message })
    );
    setIsFetching(false);
  };

  const saveNavigationSettings = (
    currentPageNumber,
    displayLength,
    currentQueries
  ) => {
    const navigationSettingsToSave = {
      page: currentPageNumber,
      displayLength,
      queries: currentQueries
    };
    dispatch(setNavigationSettings(navigationSettingsToSave));
  };

  const handleUseSavedNavigation = () => {
    setIsFetching(true);
    const sendParams = {
      display_length: (savedPage + 1) * savedDisplayedLength,
      display_start: 0,
      ...savedQueries
    };
    debounceIndexTasksRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleIndexRequest = params => {
    if (useSavedNavigationSettings) return;
    const sendParams = {
      display_length: standardDisplayLength,
      display_start: page * standardDisplayLength,
      ...params,
      ...queries
    };
    if (queries.noResults) {
      setIsFetching(false);
      setTasks([]);
      return;
    }
    saveNavigationSettings(page, standardDisplayLength, queries);
    debounceIndexTasksRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
    setIsFetching(true);
  };

  const handleRefresh = () => {
    setHasMore(true);
    setPage(0);
    setTasks([]);
    setRefresh(!refresh);
  };

  useEffect(handleIndexRequest, [refresh]);
  useEffect(handleRefresh, [moreData, queries]);
  useEffect(handleUseSavedNavigation, []);

  return { isFetching, tasks, hasMore, setMoreData, handleIndexRequest };
};

export default useFetchTasks;

import React from 'react';
import UserPaymentGroup from './UserPaymentGroup';
import InfiniteScroll from '../InfiniteScroll';
import { SpinnerLoader, NoResults } from '..';

const UserPaymentList = ({
  userPaymentsGroups,
  handleIndexRequest,
  hasMore,
  isFetching
}) => {
  return (
    <>
      <div id="user-payments-list" className="user-payments-list">
        {isFetching && <SpinnerLoader />}
        {!isFetching && userPaymentsGroups.length === 0 ? (
          <NoResults text="¡Aún no has realizado ninguna solicitud de pago!" />
        ) : (
          <InfiniteScroll
            dataLength={userPaymentsGroups.length}
            next={handleIndexRequest}
            hasMore={hasMore}
            label="pagos"
            scrollableTarget="user-payments-list"
          >
            {userPaymentsGroups.map(userPaymentGroup => {
              const [label, resourceGroup] = Object.entries(
                userPaymentGroup
              )[0];
              return (
                <UserPaymentGroup
                  userPaymentGroup={resourceGroup}
                  label={label}
                  key={label}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default UserPaymentList;

import React from 'react';
import { SpinnerLoader, NoResults } from '..';
import TaskItem from './TaskComponent';

const TasksList = ({
  isFetching = false,
  tasks,
  forMapList,
  desktopMapShowView
}) => {
  return (
    <div className="tasks-list">
      {tasks.length < 1 && !isFetching && (
        <NoResults text="No hay tareas disponibles" />
      )}
      {tasks.map(task => (
        <TaskItem
          key={task.id}
          task={task}
          forMapList={forMapList}
          desktopMapShowView={desktopMapShowView}
        />
      ))}
      {isFetching && <SpinnerLoader />}
    </div>
  );
};

export default TasksList;

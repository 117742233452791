import React from 'react';
import { useDropzone } from 'react-dropzone';

const PaymentUploadReceipt = ({ receiptFile, setReceiptFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop: acceptedFile => {
      acceptedFile.map(file => setReceiptFile(file));
    }
  });

  return (
    <div className="receipt">
      <div {...getRootProps()} className="receipt-upload">
        <input {...getInputProps()} />
        <p>
          {receiptFile
            ? `${receiptFile.name}`
            : 'Carga o arrastra tu boleta acá'}
        </p>
      </div>
    </div>
  );
};

export default PaymentUploadReceipt;

import React from 'react';
import { FeedbacksCarousel } from '../carousels';

const FeedbackSection = () => {
  return (
    <section id="feedbacks" className="landing--feedbacks">
      <p className="landing--feedbacks--title section-title">Testimonios</p>
      <div className="feedbacks">
        <FeedbacksCarousel />
      </div>
    </section>
  );
};

export default FeedbackSection;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { sendAlert } from '../../../actions/utils';
import { updateShiftUserRequest } from '../../../requests/shift_users';

const EventShiftButton = ({
  geolocationData,
  handleModalClose,
  jobStatus,
  locationImage,
  setMoreData,
  shiftUserId,
  startShift
}) => {
  const dispatch = useDispatch();

  const textBtn = {
    pending: 'Notificar',
    in_progress: 'Sí, finalizar turno',
    done: 'Cancelar lugar'
  };

  const styleClassBtn = {
    pending: 'button-confirm-notify',
    in_progress: 'confirm-finish-btn-modal',
    done: 'not-finished'
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleSuccessRequest = response => {
    dispatch(sendAlert({ kind: 'success', message: response?.data?.message }));
    handleModalClose();
    setMoreData(m => !m);
  };

  const handleUpdateShift = newJobStatus => {
    let imageParams = {};

    if (newJobStatus === 'in_progress') {
      imageParams = { ...locationImage };
    } else if (newJobStatus === 'done') {
      imageParams = {
        image_location_checkout: locationImage?.image_location_checkout,
        ...locationImage
      };
      delete imageParams.image_location;
    }

    const myParams = {
      shift_user: {
        id: shiftUserId,
        job_status: newJobStatus,
        latitude:
          newJobStatus === 'in_progress' ? geolocationData.latitude : null,
        longitude:
          newJobStatus === 'in_progress' ? geolocationData.longitude : null,
        checkout_latitude:
          newJobStatus === 'done' ? geolocationData.latitude : null,
        checkout_longitude:
          newJobStatus === 'done' ? geolocationData.longitude : null,
        ...imageParams
      }
    };

    updateShiftUserRequest(shiftUserId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: response => handleSuccessRequest(response),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateShiftButton = () => {
    switch (jobStatus) {
      case 'pending':
        handleUpdateShift('in_progress');
        break;
      case 'in_progress':
        handleUpdateShift('done');
        break;
      default:
        return false;
    }
  };

  return (
    <>
      <div className="btn-apply-to-task">
        <Button
          className={styleClassBtn[jobStatus] || ''}
          onClick={handleUpdateShiftButton}
        >
          {textBtn[jobStatus] || 'Estado no encontrado'}
        </Button>
      </div>
    </>
  );
};

export default EventShiftButton;

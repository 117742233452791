import React from 'react';
import IconGG from '../../Icons/IconGG';

const NoResults = ({ text = '', icon = 'smile-sad', color = 'blue' }) => {
  return (
    <div className="no-payment-alert">
      <IconGG className={color} icon={icon} size="4" />
      <p>{text}</p>
    </div>
  );
};

export default NoResults;

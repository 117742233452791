import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import UserPaymentModal from './UserPaymentModal';
import { SimpleCenteredModal } from '..';
import userPaymentStatusButtonProperties from './userPaymentStatusButtonProperties';

const UserPaymentItem = ({ userPayment = {} }) => {
  const {
    createdAtTime,
    id,
    parsedAmount,
    translatedStatus,
    status
  } = userPayment;
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleModalOpen = () => {
    setModalShow(true);
    setModalTitle(
      <section className="d-flex">
        <p className="font-weight-bold mr-5">Pago N° {id}</p>
        <p>
          <span
            className={`highlighted ${userPaymentStatusButtonProperties[status]}`}
          >
            {translatedStatus}
          </span>
        </p>
      </section>
    );
    setModalBody(<UserPaymentModal userPayment={userPayment} />);
  };

  return (
    <>
      <div className="user-payment">
        <div className="user-payment--time">{createdAtTime}</div>
        <div className="user-payment--info">
          <p className="font-weight-bold">Pago N° {id}</p>
          <p>
            Monto:
            <span className="highlighted bg-light-blue ml-3 font-weight-bold">
              {parsedAmount}
            </span>
          </p>
          <p>
            <span
              className={`highlighted ${userPaymentStatusButtonProperties[status]}`}
            >
              {translatedStatus}
            </span>
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button onClick={handleModalOpen} className="primary-btn big">
            Ver Detalle
          </Button>
        </div>
      </div>
      {modalShow && (
        <SimpleCenteredModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          onHide={handleModalClose}
          closeButton={false}
          handleOnIconClick={handleModalClose}
          size="sm"
        />
      )}
    </>
  );
};

export default UserPaymentItem;

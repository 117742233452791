import React from 'react';
import { TasksCarousel } from '../carousels';

const TasksSection = ({ tasks }) => {
  return (
    <section id="tasks" className="landing--tasks">
      <div className="phone">
        <img src="/images/phone-treid.webp" alt="phone" />
      </div>
      <div className="tasks">
        <p className="tasks--title section-title">Últimas Tareas</p>
        <TasksCarousel tasks={tasks} />
      </div>
    </section>
  );
};

export default TasksSection;

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import LocationMap from './LocationMap';

import EventShiftButton from './EventShiftButton';

const LocationModal = ({ eventData, handleSecondModalClose, setMoreData }) => {
  const { id: shiftUserId, startShift, jobStatus } = eventData;
  const [locationImage, setLocationImage] = useState(null);
  const [geolocationData, setGeolocationData] = useState({
    latitude: null,
    longitude: null
  });

  const addImageFile = newFile => {
    const fileImage = newFile[0];
    const code = Math.floor(Math.random() * 10000000000).toString(16);
    const infoAdditionalImage = {
      code,
      filename: fileImage.name,
      image_location: fileImage,
      loaded: false,
      size: fileImage.size,
      type: fileImage.type,
      uploaded: false
    };
    const reader = new FileReader();
    reader.onloadend = async () => {
      setLocationImage(infoAdditionalImage);
    };
    reader.readAsDataURL(fileImage);
  };

  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: { 'image/*': ['.png, .jpg'] },
    onDrop: addImageFile,
    maxFiles: 1
  });

  return (
    <>
      <p className="title"> Confirma tu dirección </p>
      <p className="plain">
        {' '}
        Esto es para verificar luego que hayas estado en el lugar indicado{' '}
      </p>
      <LocationMap
        geolocationData={geolocationData}
        setGeolocationData={setGeolocationData}
      />
      <div>
        <p className="title-image-send">Sube una imagen</p>
      </div>
      <div className="receipt">
        <div {...getRootProps()} className="receipt-upload">
          <input {...getInputProps({ capture: 'camera' })} />
          <p>
            {locationImage
              ? `Imagen '${locationImage.filename}' cargada!`
              : 'Arrastra o carga la imagen del lugar de trabajo aquí.'}
          </p>
        </div>
      </div>
      <div>
        <EventShiftButton
          geolocationData={geolocationData}
          handleModalClose={handleSecondModalClose}
          jobStatus={jobStatus}
          locationImage={locationImage}
          setMoreData={setMoreData}
          shiftUserId={shiftUserId}
          startShift={startShift}
        />
      </div>
    </>
  );
};

export default LocationModal;

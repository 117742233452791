/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import IconGG from '../../Icons'; 
import './styles.scss';

const UploadImage = props => {
  const [url, setUrl] = useState('');

  const _handleImageChange = e => {
    const { onChange } = props;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setUrl(reader.result);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  const { imageUrl } = props;
  return (
    <div className="custom-file-container">
      <div className="custom-file-avatar">
        <Image src={url || imageUrl || defaultImg} fluid />
      </div>
      <div className="input-group">
        <div className="custom-file-add-image">
          <input
            type="file"
            className="custom-file-input"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            onChange={e => _handleImageChange(e)}
          />
            <IconGG icon="math-plus" size="1" />
        </div>
      </div>
    </div>
  );
};

UploadImage.defaultProps = {
  onChange: () => {}
};

export default UploadImage;

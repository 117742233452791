import React from 'react';
import { Link } from 'react-router-dom';
import IconGG from '../../../components/Icons/IconGG';
import Logo from '../../../components/Utils/MainLogo/logo-treid-azul-o.svg';
import { officesInformation } from '../officesInformation';

const LandingFooter = () => {
  return (
    <footer className="landing--footer">
      <div className="footer-logo">
        <Link to="/">
          <img src={Logo} alt="Treid" />
        </Link>
      </div>
      <div className="offices">
        {officesInformation.map(officeInformation => (
          <OfficeInformation officeInformation={officeInformation} />
        ))}
      </div>
      <div className="social-media">
        <p>Síguenos!</p>
        <div className="social-media--links">
          <Link className="social-link" to="/">
            <IconGG className="blue" icon="facebook" size="1" />
          </Link>
          <Link className="social-link" to="/">
            <IconGG className="blue" icon="instagram" size="1" />
          </Link>
        </div>
      </div>
    </footer>
  );
};

const OfficeInformation = ({ officeInformation }) => {
  const { country, address, phone, email } = officeInformation;
  return (
    <div className="office">
      <p className="office-country">{country}</p>
      <p className="office-detail">
        <IconGG className="blue" icon="pin" size="1" />
        Dirección: {address}
      </p>
      {phone && (
        <p className="office-detail">
          <IconGG className="blue" icon="smartphone" size="1" />
          Teléfono: {phone}
        </p>
      )}
      <p className="office-detail">
        <IconGG className="blue" icon="comment" size="1" />
        Email: {email}
      </p>
    </div>
  );
};

export default LandingFooter;

import React from 'react';
import { SpinnerLoader } from '..';
import ShiftItem from './ShiftItem';

const ShiftsList = ({ isFetching = false, shifts }) => {
  return (
    <div className="tasks-list">
      {shifts.length < 1 && !isFetching && (
        <p className="text-center">SIN TURNOS ASOCIADOS</p>
      )}
      <div className="shift-list">
        {shifts.map(shift => (
          <ShiftItem
            key={shift.id}
            name={shift.name}
            period={shift.period}
            startDate={shift.parsedStartDate}
            startDateTime={shift.parsedStartDateTime}
            weekday={shift.parsedWeekday}
          />
        ))}
      </div>
      {isFetching && <SpinnerLoader />}
    </div>
  );
};

export default ShiftsList;

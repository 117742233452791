import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { NotificationView } from '../..';
import MainLogo from '../MainLogo';
import IcondedButton from './IconedButton';
import { useFetchNotifications } from '../../../hooks';
import {
  setShowingUnreadNotifications,
  hideNotificationList as dispatchHideNotificationList
} from '../../../actions/utils';

const TopBar = ({ handleShowSidebar, isDarkMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const {
    unreadNotifications,
    showingUnreadNotifications,
    hideNotificationList
  } = useSelector(state => state.utils.notifications);
  const {
    notifications,
    handleIndexRequest,
    hasMore,
    handleReadAllRequest,
    reFetchNotifications
  } = useFetchNotifications();

  const verifyIsProfile = () => {
    const profileLocation = ['/profile'];
    if (profileLocation.includes(pathname)) {
      setIsProfile(true);
    } else {
      setIsProfile(false);
    }
  };

  const handleNotificationsClick = () => {
    setOpen(m => !m);
    if (!open && unreadNotifications) {
      handleReadAllRequest();
      dispatch(setShowingUnreadNotifications(true));
    }
    if (open && showingUnreadNotifications) {
      dispatch(setShowingUnreadNotifications(false));
      reFetchNotifications();
    }
  };

  const handleProfileClick = () => {
    if (open) setOpen(false);
    history.push('/profile');
  };

  const handleTasksClick = () => {
    if (open) setOpen(false);
    history.push('/tasks');
  };

  const handleReduxCloseNotificationMenu = () => {
    if (open && hideNotificationList) {
      setOpen(false);
      dispatch(dispatchHideNotificationList(false));
    }
  };

  useEffect(handleReduxCloseNotificationMenu, [hideNotificationList]);
  useEffect(verifyIsProfile, [pathname, isProfile]);

  return (
    <>
      <div id="navbar-top" className="navbar-top">
        <div className="navbar-top--left">
          <div className="menu-box">
            <IcondedButton
              buttonHandler={handleShowSidebar}
              icon="menu-round"
              iconClass={`icon-md ${isDarkMode ? 'white' : 'blue'}`}
            />
          </div>
          <div className="navbar-top--left__logo">
            <Navbar.Brand as={Link} to="/">
              <MainLogo darkMode={isDarkMode} />
            </Navbar.Brand>
          </div>
        </div>
        <div className="navbar-top--right">
          <div className="menu-box mr-4">
            <IcondedButton
              buttonHandler={handleNotificationsClick}
              icon="bell"
              iconClass={`icon-md ${isDarkMode ? 'white' : 'blue'}`}
            >
              <div className={`notification-counter ${open ? 'open' : ''}`}>
                {unreadNotifications}
              </div>
            </IcondedButton>
          </div>
          {isProfile ? (
            <div className="menu-box">
              <IcondedButton
                buttonHandler={handleTasksClick}
                icon="flag-alt"
                iconClass={`icon-md ${isDarkMode ? 'white' : 'blue'}`}
              />
            </div>
          ) : (
            <div className="menu-box">
              <IcondedButton
                buttonHandler={handleProfileClick}
                icon="profile"
                iconClass={`icon-md ${isDarkMode ? 'white' : 'blue'}`}
              />
            </div>
          )}
        </div>
      </div>
      {open && (
        <NotificationView
          notifications={notifications}
          handleIndexRequest={handleIndexRequest}
          hasMore={hasMore}
          isDarkMode={isDarkMode}
        />
      )}
    </>
  );
};

export default TopBar;

import React from 'react';

const UserShift = ({ userShift }) => {
  const {
    shiftName,
    parsedLongDatetimeStartDate: shiftStartDate,
    taskName,
    parsedTaskPayment,
    repeatable,
    locationAddress,
    repeteableCounter,
    period
  } = userShift;

  return (
    <div className="usershift-container">
      <div className="usershift">
        <div className="usershift--dates">
          <p className="usershift--dates__shiftname">{shiftName}</p>
          <p>
            <strong> Fecha del turno</strong>
          </p>
          <p>
            {shiftStartDate}-{period.toUpperCase()}
          </p>
        </div>
        <div className="usershift--info">
          <p className="usershift--info__taskname">
            {taskName}
            {repeatable && <span className="repeteable-task">Repetible</span>}
          </p>
          <p className="usershift--info__location">{locationAddress}</p>
          <p className="usershift--info__payment">
            Pago:
            {parsedTaskPayment && (
              <span className="payment">{parsedTaskPayment}</span>
            )}
            {repeatable && (
              <span className="repeteable-task font-weight-bold">
                (x {repeteableCounter})
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserShift;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { hideNotificationList } from '../../actions/utils';

import IconGG from '../Icons/IconGG';

const Notification = ({ notification }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, icon, message, createdAt, read, notificationUrl } = notification;
  const humanizedTime = `Hace ${moment(createdAt).fromNow(true)}`;

  const handleOnClick = () => {
    dispatch(hideNotificationList(true));
    history.push(notificationUrl);
  };

  const handleOnKeyDown = e => {
    if (e.keyCode !== 13) return;
    handleOnClick();
  };

  return (
    <div
      role="button"
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      className={`notification ${read ? '' : 'new'}`}
      tabIndex={id}
    >
      <div className="notification--icon">
        <IconGG className="blue notification--icon__icon" icon={icon} />
      </div>
      <div className="notification--body">
        <p className="notification--body__message">{message}</p>
        <p className="notification--body__time">{humanizedTime}</p>
      </div>
    </div>
  );
};

export default Notification;

import React from 'react';

const Page404 = () => (
  <>
    <h2 className="text-info">404</h2>
    <h4>Página no encontrada</h4>
  </>
);

export default Page404;

import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux';
import { history } from './app/config/store';
import routes from './app/config/routes';
import { Alert } from './app/components';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import { getSettings } from './app/actions/utils';
import './index.scss';

const App = () => {
  const dispatch = useDispatch();

  const handleInit = () => {
    dispatch(getSettings());
  };

  useEffect(handleInit, []);

  return (
    <GlobalErrorBoundary>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      <Alert />
    </GlobalErrorBoundary>
  );
};

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SimpleCenteredModal } from '../Modal';
import LocationModal from './LocationModal';
import EventClickModal from './EventClickModal';

import ConfirmFinishModal from './ConfirmFinishModal';

const EventItem = ({ eventData, setMoreData }) => {
  const { jobStatus } = eventData;
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  // Second modal of check in or confirm the finish of the shift_user
  const [isSecondModalShowed, setIsSecondModalShowed] = useState(false);
  const [secondModalBody, setSecondModalBody] = useState('');
  const [secondModalTitle, setSecondModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleSecondModalClose = () => {
    setSecondModalBody('');
    setIsSecondModalShowed(false);
    setSecondModalTitle('');
  };

  const handleSecondModalOpen = () => {
    if (jobStatus === 'pending') {
      setIsSecondModalShowed(true);
      setSecondModalTitle('Notifica tu llegada');
      setSecondModalBody(
        <LocationModal
          eventData={eventData}
          handleSecondModalClose={handleSecondModalClose}
          setMoreData={setMoreData}
        />
      );
    } else if (jobStatus === 'in_progress') {
      setIsSecondModalShowed(true);
      setSecondModalTitle('Notifica tu salida');
      setSecondModalBody(
        <ConfirmFinishModal
          eventData={eventData}
          handleSecondModalClose={handleSecondModalClose}
          setMoreData={setMoreData}
        />
      );
    }
  };

  const handleEventClick = () => {
    setModalBody(
      <EventClickModal
        eventData={eventData}
        handleModalClose={handleModalClose}
        handleSecondModalOpen={handleSecondModalOpen}
        isLocationModalShowed={isSecondModalShowed}
        setIsLocationModalShowed={setIsSecondModalShowed}
        setMoreData={setMoreData}
      />
    );
    setModalShow(true);
    setModalTitle(eventData.taskName);
  };
  return (
    <>
      <Row
        key={eventData.id}
        className="event-row"
        onClick={() => handleEventClick(eventData)}
      >
        <Col lg={3} md={3} sm={3} xs={3}>
          <p className="event-time">{eventData.time}</p>
        </Col>
        <Col lg={9} md={9} sm={9} xs={9} className="event-separator">
          <p className="event-title">{eventData.title}</p>
          <p className="event-address">{eventData.address}</p>
          <p className="status">
            Estado:
            <span id={eventData.jobStatus} className={eventData.jobStatus}>
              {eventData.translatedJobStatus}
            </span>
          </p>
        </Col>
      </Row>

      <SimpleCenteredModal
        body={modalBody}
        className="event-modal"
        closeButton={false}
        handleOnIconClick={handleModalClose}
        onHide={handleModalClose}
        show={modalShow}
        size="md"
        title={modalTitle}
      />

      <SimpleCenteredModal
        body={secondModalBody}
        className="event-modal"
        closeButton={false}
        handleOnIconClick={handleSecondModalClose}
        onHide={() => setIsSecondModalShowed(false)}
        show={isSecondModalShowed}
        size="md"
        title={secondModalTitle}
      />
    </>
  );
};

export default EventItem;

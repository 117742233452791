import React from 'react';
import { Carousel } from 'react-bootstrap';

const LandingCarousel = ({ slides = {} }) => {
  const { data: slidesData } = slides;

  return (
    <Carousel className="landing-carousel" fade>
      {slidesData &&
        slidesData.map(slideData => (
          <Carousel.Item key={slideData.id} interval={10000}>
            <img
              className="landing-carousel-image d-block w-100"
              src={
                slideData?.fileInfo?.fileUrl ||
                '/images/landing-carousel/first.png'
              }
              alt={slideData?.caption || 'First Slide'}
            />
            <Carousel.Caption>
              <h3 className="carousel-caption--title">{slideData?.caption}</h3>
              <p className="carousel-caption--subtitle">{slideData?.text}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default LandingCarousel;

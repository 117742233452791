import { useDispatch } from 'react-redux';
import { messaging } from '../config/firebase';
import { sendAlert, addUnreadNotification } from '../actions/utils';

const useNotifications = () => {
  const dispatch = useDispatch();

  if (messaging) {
    messaging.onMessage(payload => {
      const { data } = payload;
      const { message } = data;
      dispatch(
        sendAlert({
          kind: 'info',
          message
        })
      );
      dispatch(addUnreadNotification());
    });
  }
};

export default useNotifications;

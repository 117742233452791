import React from 'react';
import IconGG from '../../../components/Icons/IconGG';

const BenefitsSection = () => {
  return (
    <section id="benefits" className="landing--benefits">
      <p className="landing--benefits--title section-title">
        Beneficios de Treid
      </p>
      <div className="benefits">
        <BenefitCard text="Cientos de tareas cerca tuyo." icon="infinity" />
        <BenefitCard text="Trabaja en lo que te gusta." icon="heart" />
        <BenefitCard text="Maneja tus tiempos y horarios." icon="time" />
        <BenefitCard text="Recibe tu pago en 15 días." icon="dollar" />
      </div>
    </section>
  );
};

const BenefitCard = ({ text, icon }) => (
  <div className="benefit-card">
    <div className="icon-container">
      <IconGG className="blue" icon={icon} size="2" />
    </div>
    <p className="benefit-card--text">{text}</p>
  </div>
);

export default BenefitsSection;

const officesInformation = [
  {
    country: 'Treid Chile',
    address: 'Nevería 5357, Las Condes, Santiago.',
    email: 'contacto@treid.cl'
  },
  {
    country: 'Treid Perú',
    address: 'Avenida Benavides 768, of. 1104, Miraflores, Lima.',
    email: 'contacto@treid.pe'
  }
];

// eslint-disable-next-line import/prefer-default-export
export { officesInformation };

import React from 'react';

const Home = () => {
  return (
    <>
      <p>Bienvenido a Treid</p>
    </>
  );
};

export default Home;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  PaymentUploadReceipt,
  PaymentDisclaimer
} from '../../components/Payment';
import { usePaymentRequests } from '../../hooks';
import { IconGG } from '../../components';
import { BackButton } from '../../components/ActionBtns';

const PaymentRequest = () => {
  const [receiptFile, setReceiptFile] = useState(null);

  const {
    user: {
      parsed_validated_amount: parsedValidatedAmount,
      validated_amount: validatedAmount,
      validated_shifts: validatedShifts
    }
  } = useSelector(state => state.auth);

  const { handleCreateUserPaymentRequest } = usePaymentRequests();

  const handleUploadReceipt = () => {
    const params = {
      userPayment: {
        amount: validatedAmount,
        receipt: receiptFile,
        request_user_shifts: validatedShifts
      }
    };
    handleCreateUserPaymentRequest(params);
  };

  return (
    <div>
      <h3>Solicitud de Pago</h3>
      <BackButton to="/payments" />
      <div className="request-container">
        <PaymentDisclaimer />
        <PaymentUploadReceipt
          receiptFile={receiptFile}
          setReceiptFile={setReceiptFile}
        />
        <div className="request--amount">
          <IconGG className="light-blue" icon="radio-checked" size="1" />
          <p>
            Monto Validado: <strong>{parsedValidatedAmount}</strong>
          </p>
        </div>
        <Button
          onClick={handleUploadReceipt}
          className="primary-btn big"
          disabled={!receiptFile}
        >
          SOLICITAR PAGO
        </Button>
      </div>
    </div>
  );
};

export default PaymentRequest;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TasksList from './TasksList';

const TaskInfoBox = ({ tasks, isFetching }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenInfoBox = () => {
    // eslint-disable-next-line no-unused-expressions
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };
  const {
    navigationSettings: { useSavedNavigationSettings }
  } = useSelector(state => state.utils);

  const handleScrollToTask = () => {
    if (useSavedNavigationSettings) {
      setIsOpen(true);
    }
  };

  useEffect(handleScrollToTask, []);

  return (
    <div className="info-box">
      <button
        type="button"
        className={`info-box__menu ${isOpen ? 'open' : ''}`}
        onClick={handleOpenInfoBox}
      >
        <span className="info-box__burger" />
      </button>
      <div className={`info-box__list ${isOpen ? 'open' : ''}`}>
        <div id="info-box-task-list" className="info-box__list--content">
          <TasksList tasks={tasks} forMapList isFetching={isFetching} />
        </div>
      </div>
    </div>
  );
};

export default TaskInfoBox;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import TaskMap from '../../components/TaskComponents/TaskMap';
import { SpinnerLoader } from '../../components';

import { sendAlert } from '../../actions/utils';

const TaskMapIndex = ({ tasks, setQueries, queries, isFetching }) => {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState({
    lat: -33.421878,
    lng: -70.609934
  });
  const [zoom, setZoom] = useState(10);
  const [locationFounded, setLocationFounded] = useState(false);

  const setLocation = pos => {
    const coordinates = pos.coords;
    setCurrentLocation({
      lat: coordinates.latitude,
      lng: coordinates.longitude
    });
    setLocationFounded(true);
  };

  const handleErrorOnGetLocation = () => {
    setLocationFounded(true);
    if (localStorage.getItem('hasBeenAlertedForPermissions') === 'true') return;
    const errorMessageOnPermisses =
      'No tienes activados los permisos para acceder a tu ubicación actual. Actívalos para una mejor experiencia';
    dispatch(
      sendAlert({
        kind: 'error',
        message: errorMessageOnPermisses
      })
    );
    localStorage.setItem('hasBeenAlertedForPermissions', 'true');
  };

  const getInitialLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    const prevSessionLocation = sessionStorage.getItem(
      'currentSessionLocation'
    );
    if (prevSessionLocation) {
      const { lat, lng, currentZoom } = JSON.parse(prevSessionLocation);
      setCurrentLocation({
        lat,
        lng
      });
      setZoom(currentZoom);
      return setLocationFounded(true);
    }

    return navigator.geolocation.getCurrentPosition(
      setLocation,
      handleErrorOnGetLocation,
      options
    );
  };

  useEffect(getInitialLocation, []);

  return (
    <>
      {!locationFounded && <SpinnerLoader />}
      {locationFounded && (
        <TaskMap
          center={currentLocation}
          zoom={zoom}
          tasks={tasks}
          setQueries={setQueries}
          queries={queries}
          isFetching={isFetching}
        />
      )}
    </>
  );
};

export default TaskMapIndex;

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SimpleCenteredModal, LinkBtn } from '..';
import IconGG from '../Icons/IconGG';
import TaskComponent from './TaskComponent';

const TaskLocationMarker = ({ task }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleClickMarker = () => {
    setModalBody(
      <>
        <TaskComponent task={task} desktopMapShowView showView />
        <div className="d-flex justify-content-between align-items-center mt-4 mx-2">
          <div className="w-60">
            <LinkBtn
              to={`/tasks/${task.id}?fromMap=true`}
              className="primary-btn"
            >
              Ver Tarea
            </LinkBtn>
          </div>
          <div className="w-40">
            <Button className="secondary-btn-white" onClick={handleModalClose}>
              Cerrar
            </Button>
          </div>
        </div>
      </>
    );
    setModalShow(true);
    setModalTitle(task.name);
  };

  return (
    <>
      <div className="location-marker">
        <button
          type="button"
          onClick={handleClickMarker}
          className="location-marker button"
        >
          <IconGG
            className="red mr-3 location-marker__icon"
            icon="pin"
            size="1"
          />
        </button>
      </div>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        size="md"
        closeButton={false}
        handleOnIconClick={handleModalClose}
      />
    </>
  );
};

export default TaskLocationMarker;

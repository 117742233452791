import React, { useState, useEffect } from 'react';
import TaskIndexHeader from '../../components/TaskComponents/TaskIndexHeader';
import TaskMapIndex from './TaskMapIndex';
import TasksList from '../../components/TaskComponents/TasksList';
import InfiniteScroll from '../../components/InfiniteScroll';
import { useFetchTasks } from '../../hooks';
import { parseUrlParams } from '../../services/utils';

const TaskIndex = ({ location }) => {
  const [queries, setQueries] = useState({});
  const [showMap, setShowMap] = useState(false);

  const { tasks, isFetching, hasMore, handleIndexRequest } = useFetchTasks(
    queries
  );

  const getTaskListType = () => {
    const { search: urlSearch } = location;
    const { type } = parseUrlParams(urlSearch);
    if (type === 'map') {
      setShowMap(true);
    } else {
      delete queries.noResults;
      delete queries.coordinates;
      setQueries({ ...queries });
      setShowMap(false);
    }
  };

  useEffect(getTaskListType, [location]);

  return (
    <>
      <TaskIndexHeader
        type={showMap ? 'list' : 'map'}
        text={showMap ? 'Ver Lista' : 'Ver Mapa'}
        icon={showMap ? 'play-list-search' : 'pin'}
        setShowMap={setShowMap}
      />
      {showMap ? (
        <TaskMapIndex
          tasks={tasks}
          setQueries={setQueries}
          queries={queries}
          isFetching={isFetching}
        />
      ) : (
        <InfiniteScroll
          dataLength={tasks.length}
          next={handleIndexRequest}
          hasMore={hasMore}
          label="tareas"
        >
          <TasksList tasks={tasks} isFetching={isFetching} />
        </InfiniteScroll>
      )}
    </>
  );
};

export default TaskIndex;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import IconedButton from '../Sidebar/IconedButton';

const objectOrFunction = (component, props) => {
  if (typeof component === 'function') {
    return component(props);
  }
  return component;
};

const SimpleCenteredModal = ({
  body,
  centered,
  closeButton,
  dialogClassName,
  handleClose,
  size,
  subtitle,
  title,
  handleOnIconClick,
  ...props
}) => (
  <Modal
    {...props}
    dialogClassName={size ? '' : dialogClassName}
    size={size}
    centered={centered}
  >
    <Modal.Header
      className="modal-header-padding"
      closeButton={closeButton}
      onHide={handleClose}
    >
      <Modal.Title className="text-uppercase">
        {title}
        {subtitle && <h6 className="mb-0">{subtitle}</h6>}
      </Modal.Title>
      <div className="modal-close-icon">
        <IconedButton
          icon="close-o"
          iconClass="blue"
          buttonHandler={handleOnIconClick}
        />
      </div>
    </Modal.Header>
    <Modal.Body className="modal-body-padding">
      {objectOrFunction(body)}
    </Modal.Body>
  </Modal>
);

SimpleCenteredModal.propTypes = {
  size: PropTypes.string,
  closeButton: PropTypes.bool,
  centered: PropTypes.bool
};
SimpleCenteredModal.defaultProps = {
  size: 'md',
  closeButton: true,
  centered: true
};
export default SimpleCenteredModal;

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTaskRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/tasks', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showTaskRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/tasks/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexTasksRequest = AwesomeDebouncePromise(
  indexTaskRequest,
  300
);

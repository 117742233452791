import React from 'react';
import { LandingCarousel } from '../carousels';
import { LinkBtn } from '../../../components';

const MainSection = ({ slides, landingMainTitle, landingMainSubtitle }) => {
  const defaultMainTitle =
    'La plataforma de tareas on-demand más grande de la región';
  const defaultMainSubtitle =
    'Maneja los horarios y aumenta tus ingresos, on-demand';
  return (
    <section className="landing--main">
      <div className="info">
        <p className="catchphrase">{landingMainTitle || defaultMainTitle}</p>
        <p className="sub-catchphrase">
          {landingMainSubtitle || defaultMainSubtitle}
        </p>
        <LinkBtn to="/register" className="landing--main__btn primary-btn">
          ¡Inscríbete hoy!
        </LinkBtn>
      </div>
      <div className="carousel-container">
        <LandingCarousel slides={slides} />
      </div>
    </section>
  );
};

export default MainSection;

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import SessionMainView from '../Session/SessionMainView';
import MainLogo from '../../components/Utils/MainLogo';
import { FormikInput } from '../../components';
import { requestSignIn } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { resetPasswordRequest } from '../../requests/utils';

const ResetPassword = props => {
  const { onHide, submitVariant, errors, touched } = props;
  return (
    <SessionMainView>
      <Row className="justify-content-center align-items-center vh-100">
        <MainLogo imageClass="session-main--logo" />
        <Col md={5}>
          <Form className="session-form">
            <Field name="password">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="password"
                  label="Nueva Contraseña"
                  placeholder="Contraseña"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
            <Field name="passwordConfirmation">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="password"
                  label="Confirmar Nueva Contraseña"
                  placeholder="Confirmar contraseña"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
            <Button
              variant={submitVariant}
              size="lg"
              className="mt-4 big session-btn"
              block
              type="submit"
              onClick={onHide}
            >
              CAMBIAR CONTRASEÑA
            </Button>
          </Form>
        </Col>
      </Row>
    </SessionMainView>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test(
      'password-confirmation-test',
      'Las contraseñas no coinciden',
      function equalTo(value) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    )
});

const handleSubmit = (values, { props }) => {
  const { location, dispatch } = props;
  const token = new URLSearchParams(location.search).get('token');
  resetPasswordRequest({
    dispatch,
    params: {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      token
    },
    successCallback: result => {
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
      dispatch(
        requestSignIn({
          user: {
            email: result.data.user.email,
            password: values.password
          }
        })
      );
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ResetPassword)
  )
);

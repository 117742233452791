import React from 'react';
import TaskUserPayment from './TaskUserPayment';

const TaskUserPaymentList = ({ taskUsers = [], noTitle }) => {
  return (
    <>
      {!noTitle && <h4 className="mb-3">Pagos extras</h4>}
      <div id="payment-list" className="payment-list">
        {taskUsers.map(taskUser => {
          const { id } = taskUser;
          return <TaskUserPayment taskUser={taskUser} key={id} />;
        })}
      </div>
    </>
  );
};

export default TaskUserPaymentList;

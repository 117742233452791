import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoggedLayout, NonLoggedLayout, PublicLayout } from './layouts';
import {
  AppleHandler,
  // ApplicationIndex,
  // ApplicationHistoryIndex,
  Login,
  Home,
  HomeContact,
  Register,
  RecoverPassword,
  ResetPassword,
  ChangePasswordForm,
  Landing,
  Profile,
  ProfileEdit,
  NotFound,
  TaskIndex,
  TaskShow,
  PaymentIndex,
  PaymentBankDetailsEdit,
  PaymentRequest,
  UserPaymentIndex
} from '../screens';

const routes = (
  <Switch>
    {/* LOGGED LAYOUT */}
    <LoggedLayout exact path="/home" component={Home} />
    <LoggedLayout exact path="/profile" component={Profile} />
    <LoggedLayout exact path="/profile/edit" component={ProfileEdit} />
    {/* Applications */}
    {/* <LoggedLayout exact path="/applications" component={ApplicationIndex} /> */}
    {/* <LoggedLayout
      exact
      path="/applications/history"
      component={ApplicationHistoryIndex}
    /> */}
    <LoggedLayout
      exact
      path="/change_password"
      component={ChangePasswordForm}
    />
    {/* Tasks */}
    <LoggedLayout exact path="/tasks" component={TaskIndex} />
    <LoggedLayout exact path="/tasks/:id" component={TaskShow} />
    {/* Payments */}
    <LoggedLayout exact path="/payments" component={PaymentIndex} />
    <LoggedLayout
      exact
      path="/payments/my_details"
      component={PaymentBankDetailsEdit}
    />
    <LoggedLayout exact path="/payments/request" component={PaymentRequest} />
    <LoggedLayout exact path="/payments/history" component={UserPaymentIndex} />
    {/* NON LOGGED LAYOUT */}
    <NonLoggedLayout exact path="/login" component={Login} />
    <NonLoggedLayout exact path="/register" component={Register} />
    <NonLoggedLayout exact path="/reset_password" component={ResetPassword} />
    <NonLoggedLayout
      exact
      path="/recover_password"
      component={RecoverPassword}
    />
    {/* PUBLIC LAYOUT */}
    <PublicLayout exact path="/" component={Landing} />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout exact path="/apple_handler" component={AppleHandler} />
    {/* 404 */}
    <Route component={NotFound} />
  </Switch>
);

export default routes;

import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MainLogo from '../MainLogo';

const PrincipalNavbar = ({ children }) => (
  <Navbar className="navbar-logged" expand="lg" fixed="top" variant="dark">
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Brand as={Link} to="/">
      <MainLogo />
    </Navbar.Brand>
    {children}
  </Navbar>
);

export default PrincipalNavbar;

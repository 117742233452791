import React from 'react';
import { useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import PaymentDetailsForm from './PaymentDetailsForm';
import { BackButton } from '../../components/ActionBtns';
import { usePaymentRequests } from '../../hooks';

const PaymentBankDetailsEdit = () => {
  const currentCountry = process.env.REACT_APP_CURRENT_COUNTRY;

  const { user } = useSelector(state => state.auth);
  const { handleUpdateRequest } = usePaymentRequests();

  return (
    <div>
      <h3>Datos Bancarios</h3>
      <BackButton to="/payments" />
      <PaymentDetailsForm
        user={camelCaseRecursive(user)}
        formRequest={handleUpdateRequest}
        currentCountry={currentCountry}
      />
    </div>
  );
};

export default PaymentBankDetailsEdit;

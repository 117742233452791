import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSettingsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/settings', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexSettingsRequest = AwesomeDebouncePromise(
  indexSettingsRequest,
  300
);

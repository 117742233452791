import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import MainLogo from '../MainLogo';
import IcondedButton from './IconedButton';
import TopBar from './TopBar';
import { validateToken, requestSignOut } from '../../../actions/auth';
import { deleteDeviceRequest } from '../../../requests/devices';
import useNotifications from '../../../services/notifications';

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { user } = useSelector(state => state.auth);

  const handleInit = () => {
    if (user.id === 0) {
      dispatch(validateToken());
    }
  };

  const verifyDarkMode = () => {
    const darkLocations = ['/profile'];
    if (darkLocations.includes(pathname)) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  };

  const handleLogout = () => {
    const sessionDeviceToken = localStorage.getItem('deviceToken');
    if (!sessionDeviceToken) {
      return dispatch(requestSignOut());
    }
    return deleteDeviceRequest({
      dispatch,
      params: { device: { token: sessionDeviceToken } },
      formData: true,
      successCallback: () => dispatch(requestSignOut()),
      failureCallback: () => dispatch(requestSignOut())
    });
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
    document.body.classList.remove('overflow-hidden');
  };

  const handleShowSidebar = () => {
    document.body.classList.add('overflow-hidden');
    setShowSidebar(true);
  };

  const isActive = path => {
    return pathname.includes(path);
  };

  useEffect(handleInit, []);
  useEffect(verifyDarkMode, [pathname, isDarkMode]);

  useNotifications();

  return (
    <>
      <TopBar
        handleShowSidebar={handleShowSidebar}
        handleCloseSidebar={handleCloseSidebar}
        isDarkMode={isDarkMode}
      />
      <div className={`cover-screen ${showSidebar ? 'show' : ''}`} />
      <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
        <Navbar.Brand as={Link} to="/">
          <MainLogo darkMode />
        </Navbar.Brand>
        <div className="close-box">
          <IcondedButton buttonHandler={handleCloseSidebar} icon="close-o" />
        </div>
        <Nav>
          <Nav.Link
            eventKey="tasks"
            as={Link}
            to="/tasks"
            onClick={handleCloseSidebar}
            active={isActive('/tasks')}
          >
            <span className="link-menu">Tareas</span>
          </Nav.Link>
          <Nav.Link
            eventKey="payments"
            as={Link}
            to="/payments"
            onClick={handleCloseSidebar}
            active={isActive('/payments')}
          >
            <span className="link-menu">Pagos</span>
          </Nav.Link>
          <Nav.Link
            eventKey="profile"
            as={Link}
            to="/profile"
            onClick={handleCloseSidebar}
            active={isActive('/profile')}
          >
            <span className="link-menu">Perfil</span>
          </Nav.Link>
          {/* <Nav.Link
            eventKey="applications"
            as={Link}
            to="/applications"
            onClick={handleCloseSidebar}
            active={isActive('/applications')}
          >
            <span className="link-menu">Postulaciones</span>
          </Nav.Link> */}

          <div className="logout-link">
            <Nav.Link onClick={handleLogout}>
              <span className="link-menu">Cerrar Sesión</span>
            </Nav.Link>
          </div>
        </Nav>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkBtn } from '../Button';
import Logo from '../MainLogo/logo-treid-azul-c.svg';

const NavbarTop = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScrollIntoView = (target, event) => {
    event.preventDefault();
    if (isMenuOpen) setIsMenuOpen(false);
    const targetElement = document.getElementById(target);
    const y =
      targetElement.getBoundingClientRect().top + window.pageYOffset - 80;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <nav className="landing-navbar">
      <div className="brand-title">
        <Link to="/">
          <img src={Logo} alt="Treid" />
        </Link>
      </div>
      <button type="button" onClick={handleToggle} className="toggle-button">
        <span className={`toggle-bar ${isMenuOpen ? 'open' : ''}`} />
      </button>
      <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
        <ul className="navbar-links--list">
          <li className="navbar-item">
            <LinkBtn
              to="/"
              onClick={e => handleScrollIntoView('how-it-works', e)}
            >
              ¿Como Funciona?
            </LinkBtn>
          </li>
          <li className="navbar-item">
            <LinkBtn to="/" onClick={e => handleScrollIntoView('tasks', e)}>
              Tareas
            </LinkBtn>
          </li>
          <li className="navbar-item">
            <LinkBtn to="/" onClick={e => handleScrollIntoView('benefits', e)}>
              Beneficios
            </LinkBtn>
          </li>
          <li className="navbar-item">
            <LinkBtn to="/" onClick={e => handleScrollIntoView('companies', e)}>
              Empresas
            </LinkBtn>
          </li>
          <li className="navbar-item as-btn no-bg">
            <Link to="/register">Registrarse</Link>
          </li>
          <li className="navbar-item as-btn">
            <Link to="/login">Iniciar Sesión</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavbarTop;

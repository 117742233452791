import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';

import { sendAlert } from '../../../actions/utils';
import { debounceIndexCommunesRequest } from '../../../requests/communes';
import { debounceIndexRegionsRequest } from '../../../requests/regions';
import InputSelect from './InputSelect';

const RegionCommuneSelect = ({
  allDisabled,
  communeAbbr,
  modelKey,
  regionAbbr
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [communeDisabled, setCommuneDisabled] = useState(true);
  const [communes, setCommunes] = useState([]);
  const [regions, setRegions] = useState([]);
  const dispatch = useDispatch();

  const currentValues = getIn(values, modelKey);
  const { communeId, regionId } = currentValues;

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', message: error?.response?.data?.message })
    );
  };

  const handleRegionData = response => {
    const { data } = response.data;
    setRegions(data);
    return data;
  };

  const fetchRegions = (inputValue, callback) => {
    debounceIndexRegionsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(handleRegionData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleCommuneData = response => {
    const { data } = response.data;
    setCommunes(data);
    return data;
  };

  const fetchCommunes = (inputValue, callback) => {
    debounceIndexCommunesRequest({
      dispatch,
      params: {
        name: inputValue,
        region: regionId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: response => callback(handleCommuneData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchInitialCommunes = (params = {}) => {
    debounceIndexCommunesRequest({
      dispatch,
      params: {
        ...params,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 1000
      },
      successCallback: response => handleCommuneData(response),
      failureCallback: handleFailureRequest
    });
    setCommuneDisabled(false);
  };

  const handleRegionId = () => {
    if (regionId) fetchInitialCommunes({ region: regionId });
  };

  useEffect(handleRegionId, [regionId]);

  const initialDefaultValues = (array, attributeId) => {
    const data = array.find(obj => obj.value === attributeId);
    if (data === undefined) return false;
    return data;
  };

  const handleRegionChange = value => {
    setFieldValue(`${modelKey}[regionId]`, value);
    setFieldValue(`${modelKey}[communeId]`, '');
    if (value !== '') fetchInitialCommunes({ region: value });
    else setCommuneDisabled(true);
  };

  return (
    <Row>
      <Col md={6} className="p-0 pr-4">
        <Field name={`${modelKey}[regionId]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={regionAbbr}
              isClearable={!regionAbbr}
              label="Región"
              placeholder="Región"
              isDisabled={allDisabled}
              value={initialDefaultValues(regions, regionId)}
              onChange={data => handleRegionChange(data ? data.value : '')}
              request={fetchRegions}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6} className="p-0 pl-4">
        <Field name={`${modelKey}[communeId]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={communeAbbr}
              isClearable={!communeAbbr}
              label="Comuna"
              placeholder="Comuna"
              isDisabled={allDisabled || communeDisabled}
              value={initialDefaultValues(communes, communeId)}
              onChange={data =>
                setFieldValue(field.name, data ? data.value : '')
              }
              defaultOptions={communes}
              request={fetchCommunes}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default RegionCommuneSelect;

import { defineAbility, AbilityBuilder } from '@casl/ability';
import jwt from 'jwt-decode';

const adminAbility = can => {
  can('manage', 'all');
};

const basicAbility = can => {
  can('read', 'all');
};

const setUpAbility = (can, cannot) => {
  const payload = localStorage.getItem('jwt');
  if (payload) {
    const { role } = jwt(payload);
    if (role === 'admin') {
      adminAbility(can, cannot);
    } else {
      basicAbility(can, cannot);
    }
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = (ability, role = null) => {
  const { can, cannot, rules } = new AbilityBuilder();
  if (role) {
    if (role === 'admin') {
      adminAbility(can, cannot);
    } else {
      basicAbility(can, cannot);
    }
  } else {
    setUpAbility(can, cannot);
  }
  ability.update(rules);
};

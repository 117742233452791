import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Calendar } from '../../components';
import showMeRequest from '../../requests/auth';
import { ProfileHeader, ProfileStatistics, ProfilePayment } from '../../components/ProfileComponents'
import './style.scss';

const Profile = () => {
  const [user, setUser] = useState({ avatar: [] });
  const dispatch = useDispatch();

  const dispatchGetMe = () => {
    showMeRequest({
      dispatch,
      successCallback: response => {
        if (response.data.logged_in) {
          setUser(camelCaseRecursive(response.data.user_info));
        }
      }
    });
  };

  useEffect(dispatchGetMe, []);

  return (
    <>
      <div className="profile-top">
        <ProfileHeader user={user}/>
      </div>
      <div className='profile-bottom'>
        <div className='profile-bottom__content'>
          <div className='calendar-container'>
            <Calendar/>
          </div>
          <div className="profile-payment-mobile">
            <p className="profile-payment-mobile__title">Monto validado acumulado</p>
            <p className="profile-payment-mobile__price">{user.parsedValidatedAmount}</p>
          </div>
          <div className='payment-container'>
            <ProfilePayment userPayments={user.userPayments}/>
          </div>
          <div className='statistics-container'>
            <ProfileStatistics user={user}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

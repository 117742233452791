import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTaskUserRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/task_users', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showTaskUserRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/task_users/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createTaskUserRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', '/task_users', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deleteTaskUserRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/task_users/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexTaskUsersRequest = AwesomeDebouncePromise(
  indexTaskUserRequest,
  300
);

import React from 'react';
import { useHistory } from 'react-router-dom';

const ProfileStatistics = ({ user }) => {
  const history = useHistory();
  const {
    finishedTasksPercentage,
    appliedTasksCount,
    pendingTasksCount,
    validatedTasksCount,
    paidTasksCount
  } = user;

  const handleRedirectTo = to => {
    history.push(to);
  };

  return (
    <>
      <div className="completed-tasks">
        <div className="completed-tasks__title">
          <p>Tareas realizadas con éxito</p>
        </div>
        <div className="completed-tasks__percentage">
          <div className="single-chart">
            <svg viewBox="0 0 36 36" className="circular-chart orange">
              <path
                className="circle-bg"
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className="circle"
                strokeDasharray={`${finishedTasksPercentage}, 100`}
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" className="percentage-value">
                {finishedTasksPercentage}%
              </text>
            </svg>
          </div>
        </div>
      </div>
      <div className="tasks-stats">
        <div
          className="tasks-square"
          // onClick={() => handleRedirectTo('/applications')}
          role="button"
          aria-hidden
        >
          <p className="tasks-square__title">Tareas postuladas</p>
          <p className="tasks-square__amount">{appliedTasksCount}</p>
        </div>
        <div
          className="tasks-square"
          // onClick={() => handleRedirectTo('/applications')}
          role="button"
          aria-hidden
        >
          <p className="tasks-square__title">Tareas pendientes</p>
          <p className="tasks-square__amount">{pendingTasksCount}</p>
        </div>
      </div>
      <div className="tasks-stats">
        <div
          className="tasks-square"
          onClick={() => handleRedirectTo('/payments')}
          role="button"
          aria-hidden
        >
          <p className="tasks-square__title">Tareas validadas</p>
          <p className="tasks-square__amount">{validatedTasksCount}</p>
        </div>
        <div
          className="tasks-square"
          onClick={() => handleRedirectTo('/payments/history')}
          role="button"
          aria-hidden
        >
          <p className="tasks-square__title">Tareas pagadas</p>
          <p className="tasks-square__amount">{paidTasksCount}</p>
        </div>
      </div>
    </>
  );
};

export default ProfileStatistics;

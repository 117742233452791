import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export { messaging };

export const getToken = () => {
  if (!messaging) return undefined;
  return messaging
    .getToken({
      vapidKey: process.env.REACT_APP_VAPID_KEY
    })
    .then(currentToken => {
      if (currentToken) {
        localStorage.setItem('deviceToken', currentToken);
      } else {
        localStorage.setItem('deviceToken', undefined);
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import ProfileForm from './ProfileForm';
import { IconGG, LinkBtn } from '../../components';
import { useGetUrlParams } from '../../hooks';
import { showUserRequest, updateUserRequest } from '../../requests/user';
import { setInfoUser } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';

const ProfileEdit = props => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const { fromTask } = useGetUrlParams();
  const currentTaskId = localStorage.getItem('currentTaskId');
  const comesfromTask = fromTask === 'true' && currentTaskId;

  const handleSuccessShow = response => {
    setUser(camelCaseRecursive(response.data));
  };

  const showRequest = () => {
    showUserRequest({
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  };

  useEffect(showRequest, []);

  const handleSuccessUpdate = response => {
    const { history } = props;
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Perfil actualizado correctamente'
      })
    );
    dispatch(setInfoUser(response.data));
    if (comesfromTask) {
      history.push(`/tasks/${currentTaskId}`);
      localStorage.removeItem('currentTaskId');
    } else {
      history.push('/profile');
    }
  };

  const handleUpdateRequest = params => {
    const { avatar } = params.user;
    const newParams = snakeCaseKeys(params);
    newParams.user.avatar = avatar;
    updateUserRequest({
      dispatch,
      params: newParams,
      formData: true,
      successCallback: handleSuccessUpdate
    });
  };

  return (
    <>
      <Row>
        <Col md={6} className="chevron-back">
          <LinkBtn to={comesfromTask ? `/tasks/${currentTaskId}` : '/profile'}>
            <IconGG icon="chevron-left" size="2" />
          </LinkBtn>
        </Col>
      </Row>
      <Row className="profile-edit">
        <Col md={12}>
          <ProfileForm user={user} formRequest={handleUpdateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default ProfileEdit;

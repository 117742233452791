import React from 'react';

const CompaniesSection = ({ companyLogos }) => {
  const companyLogosAreNotEmpty = companyLogos?.length > 0;
  return (
    <section
      id="companies"
      className={companyLogosAreNotEmpty ? 'landing--companies' : ''}
    >
      {companyLogosAreNotEmpty && (
        <>
          <p className="landing--companies--title section-title text-center">
            Empresas que han confiado en nosotros
          </p>
          <div className="companies">
            <CompaniesPictures companyLogos={companyLogos} />
          </div>
        </>
      )}
    </section>
  );
};

const CompaniesPictures = ({ companyLogos }) => (
  <>
    {companyLogos.map(companyLogo => (
      <div key={companyLogo} className="company">
        <div className="company-img-container">
          <img src={companyLogo || '/images/default.png'} alt="Company logo" />
        </div>
      </div>
    ))}
  </>
);

export default CompaniesSection;

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { withFormik, Field, Form, getIn } from 'formik';
import { FormikInput } from '../../components';

const ChangePasswordForm = props => {
  const { onHide, errors, touched } = props;
  return (
      <>
    <Row className="justify-content-center align-items-center h-100">
      <Col>
        <Form className="d-flex flex-column justify-content-end mt-5">
          <Field name="user[currentPassword]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                placeholder="Contraseña Actual"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="user[password]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                placeholder="Nueva Contraseña"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="user[passwordConfirmation]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                placeholder="Confirmar Nueva Contraseña"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Row className="password-buttons">
            <Col>
            <Button variant='success' size="lg" className="mt-4 ml-auto" type="submit" onClick={onHide} style={{color: '#1B1F27'}}>
                Cambiar Contraseña
            </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
    </>
  );
};

const initialValues = {
  user: {
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  }
};
const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    currentPassword: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres'),
    password: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres'),
    passwordConfirmation: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
  })
});
const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};
export default withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit
})(ChangePasswordForm);
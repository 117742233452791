export const utilsTypes = {
  // SETTINGS
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  // SLIDES
  GET_SLIDES_REQUEST: 'GET_SLIDES_REQUEST',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
  GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
  // PAGE
  GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'GET_PAGE_FAILURE',
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',
  // TOKEN
  SUBMIT_TOKEN_REQUEST: 'SUBMIT_TOKEN_REQUEST',
  SUBMIT_TOKEN_SUCCESS: 'SUBMIT_TOKEN_SUCCESS',
  SUBMIT_TOKEN_FAILURE: 'SUBMIT_TOKEN_FAILURE',
  // NAVIGATION SETTINGS
  SET_CURRENT_NAVIGATION_SETTINGS: 'SET_CURRENT_NAVIGATION_SETTINGS',
  SET_STOP_USING_SAVED_NAVIGATION: 'SET_STOP_USING_SAVED_NAVIGATION',
  SET_CURRENT_TASK_NAVIGATION: 'SET_CURRENT_TASK_NAVIGATION',
  // NOTIFICATIONS
  SET_UNREAD_NOTIFICATIONS: 'SET_UNREAD_NOTIFICATIONS',
  ADD_UNREAD_NOTIFICATION: 'ADD_UNREAD_NOTIFICATION',
  SET_SHOWING_UNREAD_NOTIFICATIONS: 'SET_SHOWING_UNREAD_NOTIFICATIONS',
  HIDE_NOTIFICATION_LIST: 'HIDE_NOTIFICATION_LIST'
};

// SETTINGS
export const getSettings = () => ({ type: utilsTypes.GET_SETTINGS_REQUEST });

// SLIDE
export const getSlides = () => ({ type: utilsTypes.GET_SLIDES_REQUEST });

// PAGE
export const getPage = url => ({
  type: utilsTypes.GET_PAGE_REQUEST,
  url
});

// DEVICE
export const submitToken = userId => ({
  type: utilsTypes.SUBMIT_TOKEN_REQUEST,
  userId
});

// ALERT
export const closeAlert = params => ({ type: utilsTypes.CLOSE_ALERT, params });
export const sendAlert = params => ({ type: utilsTypes.SET_ALERT, ...params });

export const setHeaders = params => ({
  type: utilsTypes.SET_HEADERS,
  ...params
});

// NAVIGATION SETTINGS
export const setNavigationSettings = params => ({
  type: utilsTypes.SET_CURRENT_NAVIGATION_SETTINGS,
  ...params
});

export const setStopUsingNavigationSettings = () => ({
  type: utilsTypes.SET_STOP_USING_SAVED_NAVIGATION
});

export const setCurrentTaskNavigation = taskId => ({
  type: utilsTypes.SET_CURRENT_TASK_NAVIGATION,
  taskId
});

// NOTIFICATIONS
export const setUnreadNotifications = unreadNotifications => ({
  type: utilsTypes.SET_UNREAD_NOTIFICATIONS,
  unreadNotifications
});

export const addUnreadNotification = () => ({
  type: utilsTypes.ADD_UNREAD_NOTIFICATION
});

export const setShowingUnreadNotifications = payload => ({
  type: utilsTypes.SET_SHOWING_UNREAD_NOTIFICATIONS,
  payload
});

export const hideNotificationList = payload => ({
  type: utilsTypes.HIDE_NOTIFICATION_LIST,
  payload
});

import React from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RemoteSelect from './RemoteSelect';
import Icon from '../../Icons';
import './style.scss';

const initialDefaultValue = (defaultValue, props) => {
  if (defaultValue) return defaultValue;

  const { isMulti, model, values, value } = props;
  if (!model) return value;
  const [defaultModel, attribute] = model;
  if (values) {
    const { [`${attribute}${isMulti ? 'Ids' : 'Id'}`]: vAttributeId } = values;
    const { [`${attribute}${isMulti ? 'Ids' : 'Id'}`]: attributeId } = defaultModel;

    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      const { [`${attribute}${isMulti ? 's' : ''}`]: atributes } = defaultModel;
      return atributes;
    }
    if ((isMulti && vAttributeId.length === 0) || vAttributeId === '') return null;
  }
  return undefined;
};

const InputSelect = ({
  abbr,
  delayHide,
  delayShow,
  defaultValue,
  direction,
  disabled,
  error,
  helpText,
  label,
  margin,
  request,
  tooltipText,
  touched,
  ...props
}) => (
  <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        {tooltipText && (
          <ButtonToolbar className="ml-2">
            <OverlayTrigger
              key={direction}
              placement={direction}
              delay={{ show: delayShow, hide: delayHide }}
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <Icon icon="help-circle" height="15px" width="15px" />
            </OverlayTrigger>
          </ButtonToolbar>
        )}
      </Form.Label>
    )}
    <RemoteSelect
      {...props}
      value={initialDefaultValue(defaultValue, props)}
      loadOptions={request}
      disabled={disabled}
    />
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

InputSelect.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string
};

InputSelect.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: ''
};

export default InputSelect;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import TaskComponent from '../../components/TaskComponents/TaskComponent';
import { BackButton } from '../../components/ActionBtns';
import { SpinnerLoader } from '../../components';
import { showTaskRequest } from '../../requests/tasks';
import { setCurrentTaskNavigation, sendAlert } from '../../actions/utils';
import { parseUrlParams } from '../../services/utils';

const TaskShow = ({
  match: {
    params: { id }
  },
  location
}) => {
  const { search: urlSearch } = location;
  const urlQueries = parseUrlParams(urlSearch);
  const { fromMap, fromApplications, fromCalendar } = urlQueries;
  const comesFromMap = fromMap === 'true';
  const [task, setTask] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', message: error?.response?.data?.message })
    );
    setOnRequest(false);
  };

  const handleSuccessShowRequest = response => {
    setTask(camelCaseRecursive(response.data));
    dispatch(setCurrentTaskNavigation(id));
    setOnRequest(false);
  };

  const fetchTask = () => {
    showTaskRequest(id, {
      dispatch,
      successCallback: handleSuccessShowRequest,
      failureCallback: handleFailureRequest
    });
  };

  const goBack = () => {
    if (fromApplications) {
      return '/applications';
    }
    if (fromMap) {
      return `/tasks${comesFromMap ? '?type=map' : ''}`;
    }
    if (fromCalendar) {
      return '/profile?fromTaskShow=true';
    }
    return '/';
  };

  useEffect(fetchTask, []);

  return (
    <div>
      <BackButton to={goBack()} />
      {onRequest && <SpinnerLoader />}
      {task?.id && !onRequest && <TaskComponent task={task} showView />}
      {!task?.id && !onRequest && <NoTaskFound />}
    </div>
  );
};

const NoTaskFound = () => (
  <div className="bg-warning-yellow rounded text-center font-weight-bold w-100 mx-auto py-5 px-3">
    Lo sentimos. No hay registro de esta tarea
  </div>
);

export default TaskShow;

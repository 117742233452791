import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { LinkBtn } from '..';
import IconGG from '../Icons/IconGG';
import statusButtonProperties from './statusButtonProperties';

const TaskButtons = ({
  fromMap,
  isOpen,
  handleOpenDetails,
  isDesktop = false,
  task: { id: taskId, applicationInfo }
}) => {
  const [btnProperties, setBtnProperties] = useState({
    text: '',
    bgColorClass: ''
  });

  const handleSetButtonProperties = () => {
    if (applicationInfo) {
      const { applicationStatus } = applicationInfo;
      setBtnProperties(statusButtonProperties[applicationStatus]);
    } else {
      setBtnProperties({ text: 'Postular', bgColorClass: 'bg-dark-blue ' });
    }
  };

  useEffect(handleSetButtonProperties, [applicationInfo]);

  return (
    <div className={`task-buttons ${isDesktop ? 'desktop' : ''}`}>
      <div className="task-buttons--primary">
        <LinkBtn
          to={`/tasks/${taskId}?fromMap=${fromMap ? 'true' : 'false'}`}
          className={`primary-btn ${btnProperties.bgColorClass}`}
        >
          {btnProperties.text}
        </LinkBtn>
      </div>
      <div className="task-buttons--secondary">
        <Button
          className={`secondary-btn-white ${isOpen ? 'show' : ''}`}
          onClick={handleOpenDetails}
        >
          {isOpen ? 'Ver menos' : 'Ver más'}
          <IconGG className="blue" icon="chevron-down" size="1" />
        </Button>
      </div>
    </div>
  );
};

export default TaskButtons;

import React, { useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import PropTypes from 'prop-types';
import ListDayEvents from './ListDayEvents';
import BasicMessage from '../Message';
import './style.scss';

const EventCalendar = props => {
  const {
    alertType,
    dayEvents,
    events,
    fetchEvents,
    handleDateClick,
    handleEventContent,
    message,
    previousClickedDay,
    selectedDate,
    setMoreData,
    shouldListDayEvents,
  } = props;
  const calendarRef = useRef();

  const getFirstDayOfMonth = data => {
    const middleOfMonth = new Date(
      data.start.setDate(data.start.getDate() + 10)
    );
    const firstDay = new Date(
      middleOfMonth.getFullYear(),
      middleOfMonth.getMonth(),
      1
    );
    fetchEvents(firstDay);
  };

  return (
    <>
      <div>
        <p className="calendar-leyend">
          <span className="today">Hoy</span>
          <span className="busy-days">Día seleccionado</span>
        </p>
        <div className="calendar-top">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev',
              center: 'title',
              right: 'next'
            }}
            firstDay={1}
            buttonText={{
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              list: 'Lista'
            }}
            locale="es"
            initialView="dayGridMonth"
            weekends
            events={events}
            eventColor="#668CB5"
            eventContent={handleEventContent}
            longPressDelay={200}
            dateClick={handleDateClick}
            initialDate={previousClickedDay || new Date()}
            datesSet={getFirstDayOfMonth}
          />
        </div>
      </div>
      <div className="calendar-bottom">
        {localStorage.getItem(alertType) !== 'true' && (
          <BasicMessage body={message} alertType={alertType} />
        )}
        {shouldListDayEvents ? (
          <>
            <ListDayEvents
              dayEvents={dayEvents}
              selectedDate={selectedDate}
              setMoreData={setMoreData}
            />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
EventCalendar.propTypes = {
  shouldListDayEvents: PropTypes.bool
};
EventCalendar.defaultProps = {
  shouldListDayEvents: false
};
export default EventCalendar;

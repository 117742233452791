import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexUserPaymentsRequest } from '../requests/user_payments';

import { sendAlert } from '../actions/utils';

const useFetchNotifications = () => {
  const [userPayments, setUserPayments] = useState([]);
  const [moreData, setMoreData] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const standardDisplayLength = 20;

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    const responseUserPayments = response.data.data;
    const totalAmountOfData = response.data.metadata.amount;
    setIsFetching(false);
    setUserPayments([
      ...userPayments,
      ...camelCaseRecursive(responseUserPayments)
    ]);
    setHasMore(userPayments.length < totalAmountOfData);
    setPage(page + 1);
  };

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Ops, hubo un problema al buscar tu historial de pago. Por favor inténtalo nuevamente';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setIsFetching(false);
  };

  const handleIndexRequest = params => {
    const sendParams = {
      display_length: standardDisplayLength,
      display_start: page * standardDisplayLength,
      sort_column: 'created_at',
      sort_direction: 'desc',
      ...params
    };
    debounceIndexUserPaymentsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
    setIsFetching(true);
  };

  const reFetchUserPayments = () => {
    setUserPayments([]);
    setPage(0);
    setMoreData(m => !m);
  };

  useEffect(handleIndexRequest, [moreData]);

  return {
    userPayments,
    hasMore,
    isFetching,
    handleIndexRequest,
    reFetchUserPayments
  };
};

export default useFetchNotifications;

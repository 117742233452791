import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { indexSlidesRequest, deleteSlideRequest } from '../../requests/slides';
import {
  BasicActionBtns,
  ComponentDataTable,
  DefaultModal
} from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: '1'
  },
  {
    name: 'Posición',
    selector: 'position',
    sortable: true,
    grow: '1'
  },
  {
    name: 'Caption',
    selector: 'caption',
    sortable: true,
    grow: '2'
  },
  {
    name: 'Creación',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'Activo',
    grow: '1',
    sortable: true,
    selector: 'active',
    cell: item => (item.active ? 'Si' : 'No')
  },
  {
    cell: item => (
      <BasicActionBtns
        item={item}
        clickHandler={clickHandler}
        showClick
        editClick
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const SlideDataTable = props => {
  const [onRequest, setOnRequest] = useState(false);
  const [slides, setSlides] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalBody, setModalBody] = useState(null);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const _slides = response.data.data;
    const { amount: _amount } = response.data.metadata;
    setSlides(_slides);
    setAmount(_amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    setMoreData(!moreData);
  };

  const removeSlide = slideId => {
    deleteSlideRequest(slideId, {
      dispatch,
      successCallback: () => handleSuccessRemove(slideId)
    });
  };

  const handleButtonClick = (item, action) => {
    const { history } = props;
    switch (action) {
      case 'show':
        history.push(`/slides/${item.id}`);
        break;
      case 'edit':
        history.push(`/slides/${item.id}/edit`);
        break;
      case 'remove':
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Realmente quires eliminar slide: ${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleModalConfirm = () => {
    removeSlide(modalItem.id);
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'caption':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={slides}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
      />
      <DefaultModal
        title="Eliminar Slide"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default withRouter(connect()(SlideDataTable));

import React from 'react';
import { Link } from 'react-router-dom';
import IconGG from '../../components/Icons/IconGG';
import { LinkBtn } from '../../components';
import MainLogo from '../../components/Utils/MainLogo';

const NotFound = () => (
  <>
    <header className="not-found--navbar">
      <div className="navbar-item main-logo">
        <Link to="/">
          <MainLogo darkMode />
        </Link>
      </div>
      <div className="navbar-item">
        <Link to="/">Inicio</Link>
      </div>
    </header>
    <div className="not-found">
      <h1>404</h1>
      <div className="not-found--body">
        <IconGG className="blue" icon="smile-sad" />
        <p className="font-weight-bold">
          ¡Oops! No pudimos encontrar la página.
        </p>
      </div>
      <div className="btn-back">
        <LinkBtn to="/" className="primary-btn big px-4">
          Volver a Treid
        </LinkBtn>
      </div>
    </div>
  </>
);

export default NotFound;

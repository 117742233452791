import React from 'react';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Rating = ({ value, text, color }) => {
  const handleFirstStar = () => {
    if (value <= '0.5' && value >= '0.1') {
      return <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />;
    }
    if (value >= '1.0') {
      return <FontAwesomeIcon icon={faStar} style={{ color }} />;
    }
    if (value > '0.5' && value <= '1.0') {
      return <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />;
    }
    return (
      <FontAwesomeIcon icon={faStar} style={{ color: 'rgb(44, 60, 82)' }} />
    );
  };

  const handleSecondStar = () => {
    if (value >= '2.0') {
      return <FontAwesomeIcon icon={faStar} style={{ color }} />;
    }
    if (value > '1.0' && value <= '1.9') {
      return <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />;
    }
    return (
      <FontAwesomeIcon icon={faStar} style={{ color: 'rgb(44, 60, 82)' }} />
    );
  };

  const handleThirdStar = () => {
    if (value >= '3.0') {
      return <FontAwesomeIcon icon={faStar} style={{ color }} />;
    }
    if (value > '2.0' && value <= '2.9') {
      return <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />;
    }
    return (
      <FontAwesomeIcon icon={faStar} style={{ color: 'rgb(44, 60, 82)' }} />
    );
  };

  const handleFourthStar = () => {
    if (value >= '4.0') {
      return <FontAwesomeIcon icon={faStar} style={{ color }} />;
    }
    if (value > '3.0' && value <= '3.9') {
      return <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />;
    }
    return (
      <FontAwesomeIcon icon={faStar} style={{ color: 'rgb(44, 60, 82)' }} />
    );
  };

  const handleFifthStar = () => {
    if (value === '5.0') {
      return <FontAwesomeIcon icon={faStar} style={{ color }} />;
    }
    if (value > '4.0' && value <= '4.9') {
      return <FontAwesomeIcon icon={faStarHalfAlt} style={{ color }} />;
    }
    return (
      <FontAwesomeIcon icon={faStar} style={{ color: 'rgb(44, 60, 82)' }} />
    );
  };

  return (
    <p className="rating">
      <span>{handleFirstStar()}</span>
      <span>{handleSecondStar()}</span>
      <span>{handleThirdStar()}</span>
      <span>{handleFourthStar()}</span>
      <span>{handleFifthStar()}</span>
      <span className="rating-text" style={{ color: 'white' }}>
        {text && text}
      </span>
    </p>
  );
};

Rating.defaultProps = {
  color: '#FFC107'
};

export default Rating;

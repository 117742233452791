import React from 'react';
import IconGG from '../Icons/IconGG';

const PaymentDisclaimer = () => {
  return (
    <div className="request-disclaimer">
      <header className="title d-flex align-items-center">
        <IconGG className="blue mr-2" icon="danger" size="1" />
        <h4>Importante</h4>
      </header>
      <p className="disclaimer-text">
        <p>
          Recuerda emitir una boleta electrónica de honorarios a nombre de
          XXXXX, por la prestación de servicios XXXXX y por el monto validado
          que se indica más abajo.
        </p>
        <p>
          Para finalizar tu solicitud de pago, adjunta la boleta electrónica a
          continuación.
        </p>
      </p>
    </div>
  );
};

export default PaymentDisclaimer;

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { LinkBtn, FormikInput } from '../../components';
import MainLogo from '../../components/Utils/MainLogo';
import { requestSignIn } from '../../actions/auth';
import SessionMainView from '../Session/SessionMainView';

const Login = props => {
  const { onHide, submitVariant, errors, touched } = props;

  return (
    <>
      <SessionMainView>
        <Row className="justify-content-center align-items-center vh-100">
          <MainLogo imageClass="session-main--logo" />
          <Col md={5}>
            <Form className="session-form">
              <Field name="email">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Correo"
                    placeholder="Email"
                    inputType="email"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    label="Contraseña"
                    placeholder="Contraseña"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Button
                variant={submitVariant}
                size="lg"
                className="mt-4 big session-btn"
                block
                type="submit"
                onClick={onHide}
              >
                INICIAR SESIÓN
              </Button>
            </Form>

            <div className="session-links">
              <LinkBtn to="/register">
                ¿No tienes cuenta? <strong>Crea una aquí</strong>
              </LinkBtn>
              <LinkBtn to="/recover_password">Olvidé mi contraseña</LinkBtn>
            </div>
          </Col>
        </Row>
      </SessionMainView>
    </>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  password: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    requestSignIn({
      user: {
        email: values.email,
        password: values.password
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '..';
import IconGG from '../Icons/IconGG';

const BackButton = ({ to, onClick }) => {
  return (
    <Row>
      <Col md={2}>
        <LinkBtn
          block
          className="back-btn"
          variant="light-darker"
          to={to}
          onClick={onClick}
        >
          <IconGG className="blue" icon="chevron-left" size="1.5" />
        </LinkBtn>
      </Col>
    </Row>
  );
};

export default BackButton;

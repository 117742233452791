import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn } from '../Button';

const EventClickModal = ({
  eventData,
  handleModalClose,
  handleSecondModalOpen
}) => {

  const { jobStatus } = eventData;
  const addButton = ['pending', 'in_progress'].includes(jobStatus);

  const handleModal = () => {
    handleSecondModalOpen();
    handleModalClose();
  };

  return (
    <>
      <Row key={eventData.id} className="event-row">
        <Col lg={3} md={3} sm={3} xs={3}>
          <p className="event-time">{eventData.time}</p>
        </Col>
        <Col lg={9} md={9} sm={9} xs={9} className="event-separator">
          <p className="event-title">{eventData.title}</p>
          <p className="event-address">{eventData.address}</p>
          <p className="status">
            Estado:
            <span id={eventData.jobStatus} className={jobStatus}>
              {eventData.translatedJobStatus}
            </span>
          </p>
        </Col>
      </Row>
      <section
        className={addButton ? 'event-data-show-task' : 'event-data-alone'}
      >
        <LinkBtn to={`/tasks/${eventData.taskId}?fromCalendar=true`}>
          Ver Detalle Tarea
        </LinkBtn>
        {jobStatus === 'in_progress' && (
          <div role="button" className="button-notify" onClick={handleModal}>
            Finalizar turno
          </div>
        )}
        {jobStatus === 'pending' && (
          <div role="button" className="button-notify" onClick={handleModal}>
            Notificar llegada
          </div>
        )}
      </section>
    </>
  );
};

export default EventClickModal;

import React from 'react';

const PaymentAmounts = ({ parsedValidatedAmount, parsedDoneAmount }) => {
  return (
    <div className="payment-amounts-container">
      <div className="amount-box bg-light-blue">
        <p className="amount-box__title">Monto validado acumulado</p>
        <p className="amount-box__price">{parsedValidatedAmount}</p>
      </div>
      <div className="amount-box bg-light-blue-secondary">
        <p className="amount-box__title">Monto por validar</p>
        <p className="amount-box__price">{parsedDoneAmount}</p>
      </div>
    </div>
  );
};

export default PaymentAmounts;

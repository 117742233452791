const educationLevelTypes = [
    { label: 'Sin Escolaridad', value: 'no_school' },
    { label: 'Básica Incompleta', value: 'basic_incomplete' },
    { label: 'Básica Completa', value: 'basic_complete' },
    { label: 'Media Incompleta', value: 'media_incomplete' },
    { label: 'Media Completa', value: 'media_complete' },
    { label: 'Superior técnica Profesional Incompleta', value: 'technical_incomplete' },
    { label: 'Superior Técnica Profesional Completa', value: 'technical_complete' },
    { label: 'Universitaria Incompleta', value: 'university_incomplete' },
    { label: 'Universitaria Completa', value: 'university_complete' }
  ];
  
  const genderTypes = [
    { label: 'Masculino', value: 'male' },
    { label: 'Femenino', value: 'female' }
  ];

  const activities = [
    { label: 'Desempleado', value: 'unemployed' },
    { label: 'Estudiante', value: 'student' },
    { label: 'Trabajador Part Time', value: 'part_time' },
    { label: 'Trabajador Full Time', value: 'full_time' }
  ];
  
  export { educationLevelTypes, genderTypes, activities };
  
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import TaskUserPaymentList from './TaskUserPaymentList';
import UserShift from './UserShift';
import { NoResults } from '..';

const UserPaymentModal = ({ userPayment = {} }) => {
  const { status, shiftUsers, taskUsers, rejectionReason } = userPayment;

  return (
    <>
      {status === 'rejected' ? (
        <NoResults
          text={
            rejectionReason ||
            'Hubo un problema con su pago. Por favor contactar Administración'
          }
          icon="close-o"
          color="red"
        />
      ) : (
        <>
          <h4 className="mt-3 mb-4">Resumen de Pagos</h4>
          <Tabs
            defaultActiveKey="validated_shifts"
            id="uncontrolled-tab-example"
            className="mb-4"
          >
            <Tab eventKey="validated_shifts" title="Turnos validados">
              <div id="payment-list" className="payment-list payment-modal">
                {shiftUsers.map(userShift => {
                  const { id } = userShift;
                  return <UserShift userShift={userShift} key={id} />;
                })}
              </div>
            </Tab>
            {taskUsers.length > 0 && (
              <Tab eventKey="extra_payments" title="Pagos extras">
                <TaskUserPaymentList taskUsers={taskUsers} noTitle />
              </Tab>
            )}
          </Tabs>
        </>
      )}
    </>
  );
};

export default UserPaymentModal;

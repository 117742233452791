import React from 'react';
import {
  MainSection,
  HowItWorksSection,
  TasksSection,
  BenefitsSection,
  FeedbackSection,
  CompaniesSection,
  LandingFooter
} from './sections';
import { useFetchSettings } from '../../hooks';

const Landing = () => {
  const { settings } = useFetchSettings();
  const {
    landingMainTitle,
    landingMainSubtitle,
    companyLogo1,
    companyLogo2,
    companyLogo3,
    companyLogo4,
    companyLogo5,
    companyLogo6,
    tasks,
    slides
  } = settings;

  const companyLogos = [
    companyLogo1,
    companyLogo2,
    companyLogo3,
    companyLogo4,
    companyLogo5,
    companyLogo6
  ].filter(e => e);

  return (
    <div className="landing">
      <MainSection
        slides={slides}
        landingMainTitle={landingMainTitle}
        landingMainSubtitle={landingMainSubtitle}
      />
      <HowItWorksSection />
      <TasksSection tasks={tasks} />
      <BenefitsSection />
      <FeedbackSection />
      <CompaniesSection companyLogos={companyLogos} />
      <LandingFooter />
    </div>
  );
};

export default Landing;

import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';

import { FormikInput, FormikCheckBox, UploadImage } from '../../components';

const SlideForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';

  return (
    <Form>
      <Field name="slide[image]">
        {({ field }) => (
          <UploadImage
            {...field}
            imageUrl={getIn(props.slide.fileInfo, 'fileUrl')}
            onChange={_avatar => setFieldValue(field.name, _avatar)}
          />
        )}
      </Field>

      <Field name="slide[position]">
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            inputType="number"
            label="Posición"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      <Field name="slide[caption]">
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            inputType="text"
            label="Caption"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      <Field name="slide[active]">
        {({ field }) => (
          <FormikCheckBox {...field} field={field} label="Activo" custom />
        )}
      </Field>
      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { id, fileInfo, position, caption, active } = props.slide;
  return {
    slide: {
      id,
      image: getIn(fileInfo, 'fileSignature'),
      position,
      caption,
      active
    }
  };
};

const validationSchema = Yup.object().shape({
  slide: Yup.object().shape({
    image: Yup.mixed().required('Requerido'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    caption: Yup.string().required('Requerido'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SlideForm);

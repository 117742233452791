import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const LocationMap = ({ geolocationData, setGeolocationData }) => {
  const [isGeoInfoSetted, setIsGeoInfoSetted] = useState(false);
  const [counterRefresh, setCounterRefresh] = useState(0);

  const getNavigationData = async () => {
    navigator.geolocation.getCurrentPosition(pos => {
      console.log(pos?.coords?.latitude);
      setGeolocationData({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      });
      const geoInfo =
        Boolean(pos.coords.latitude) && Boolean(pos.coords.longitude);
      setIsGeoInfoSetted(geoInfo);
    });
  };

  useEffect(() => {
    const checkForPermission = async () => {
      await getNavigationData();
    };
    checkForPermission();
  }, [counterRefresh]);

  const handleRefresh = () => setCounterRefresh(counterRefresh + 1);

  const mapOptions = {
    disableDefaultUI: true,
    gestureHandling: 'none',
    zoomControl: true
  };

  return (
    <div className="map-current-location">
      {isGeoInfoSetted ? (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
          }}
          defaultCenter={{
            lat: geolocationData.latitude,
            lng: geolocationData.longitude
          }}
          defaultZoom={15}
          options={mapOptions}
        >
          <Marker geolocationData />
        </GoogleMapReact>
      ) : (
        <div className="text-center">
          <p>
            Por favor permite la geolocalización en tu navegador (o pestaña
            emergente) y refresca
          </p>
          <div onClick={handleRefresh} className="button-refresh" role="button">
            Refrescar
          </div>
        </div>
      )}
    </div>
  );
};

const Marker = ({ geolocationData }) => {
  return (
    <div
      lat={geolocationData.latitude}
      lng={geolocationData.longitude}
      className="marker-current-location"
    >
      <FontAwesomeIcon icon={faMapMarkerAlt} className="marker-icon-style" />
    </div>
  );
};

export default LocationMap;

import React from 'react';
import UserPaymentItem from './UserPaymentItem';

const UserPaymentGroup = ({ userPaymentGroup, label }) => {
  return (
    <>
      <div className="payment-group">
        <section className="payment-group-header">{label}</section>
        {userPaymentGroup.map(userPayment => (
          <UserPaymentItem userPayment={userPayment} key={userPayment.id} />
        ))}
      </div>
    </>
  );
};

export default UserPaymentGroup;
